import { enums, formatters, getStatusesAfterStatus, notUndefinedOrNull, types } from "@fraction/shared";
import _ from "lodash";
import { useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { ChecklistApp } from "src/api/fraction";
import { useAuth } from "src/auth";
import { AppChat, AppChatHandle } from "src/components/AppChat";
import { AppChecklistItems } from "src/components/AppChecklistItems";
import { getChecklistItemNameString } from "src/components/ChecklistItemName";
import { InfiniteAppQueryBadge } from "src/components/InfiniteAppQueryBadge";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import config from "src/config";
import { useInfiniteApplicationsQuery } from "src/hooks/useApplication";
import { useCachedState } from "src/hooks/useCache";
import useTabs from "src/hooks/useTabs";

export function Tasks() {
  const { user } = useAuth();

  const [showConveyancerChats, setShowConveyancerChats] = useCachedState(true, "showConveyancerChats");
  const [showBrokerChats, setShowBrokerChats] = useCachedState(true, "showBrokerChats");
  const [showAnyAppWithAConveyancerChat, setShowAnyAppWithAConveyancerChat] = useCachedState(
    false,
    "showAnyAppWithAConveyancerChat"
  );
  const [showAnyAppWithABrokerChat, setShowAnyAppWithABrokerChat] = useCachedState(
    false,
    "showAnyAppWithABrokerChat"
  );

  const {
    data: apps,
    isFetching,
    dataUpdatedAt,
    refetch,
    isPending,
    count,
  } = useInfiniteApplicationsQuery({
    status: "active",
    accountType: "employee",
  });

  const josh = config.isProd
    ? []
    : [apps?.find((x) => x.applicants?.find((y) => y.user?.email === "josh@fraction.com"))].filter(
        notUndefinedOrNull
      );

  const { Tabs, activeTab } = useTabs({
    options: [
      {
        label: "Mortgage specialist view (commitment letter sent & receiving docs)",
        value: "mortgage-specialist",
      },
      { label: "Mortgage administrator view (post conveyancer engaged)", value: "mortgage-administrator" },
      { label: "All apps", value: "all" },
    ],
    defaultValue: user?.internalEmployee?.types?.includes(enums.InternalEmployeeType.MORTGAGE_ADMINISTRATOR)
      ? "mortgage-administrator"
      : "mortgage-specialist",
  });

  const statusesToShow = useMemo(() => {
    switch (activeTab) {
      case "mortgage-specialist":
        return [enums.ApplicationStatus.RECEIVING_DOCS, enums.ApplicationStatus.COMMITMENT_LETTER_SENT];
      case "mortgage-administrator":
        return getStatusesAfterStatus(enums.ApplicationStatus.CONVEYANCER_ENGAGED, true);
      case "all":
        return getStatusesAfterStatus(enums.ApplicationStatus.UNDERWRITING, true);
    }
  }, [activeTab]);

  const filteredApps = apps?.filter(
    (x) =>
      ((x.status && statusesToShow.includes(x.status)) ||
        (showAnyAppWithAConveyancerChat && !!x.fiveMostRecentConveyancerChats?.length) ||
        (showAnyAppWithABrokerChat && !!x.fiveMostRecentBrokerChats?.length)) &&
      !x.applicants?.find((y) => y?.user?.email?.includes("@fraction.com"))
  );

  const appsWithConveyancerChats = filteredApps?.filter((x) => x.fiveMostRecentConveyancerChats?.length);
  const appsWithBrokerChats = filteredApps?.filter((x) => x.fiveMostRecentBrokerChats?.length);
  const appsWithoutChats = _.sortBy(
    filteredApps?.filter(
      (x) => !x.fiveMostRecentConveyancerChats?.length && !x.fiveMostRecentBrokerChats?.length
    ),
    "status"
  );

  return (
    <div className="w-full pr-6 flex flex-col gap-2 overscroll-y-contain">
      <div className="flex flex-row gap-6">
        <div className="flex items-center space-x-2">
          <Checkbox
            id="showAnyAppWithAConveyancerChat"
            checked={showAnyAppWithAConveyancerChat}
            onCheckedChange={(e) => setShowAnyAppWithAConveyancerChat(e === "indeterminate" ? false : e)}
          />
          <label
            htmlFor="showAnyAppWithAConveyancerChat"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Show any app with a conveyancer chat
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="showConveyancerChats"
            checked={showConveyancerChats}
            onCheckedChange={(e) => setShowConveyancerChats(e === "indeterminate" ? false : e)}
          />
          <label
            htmlFor="showConveyancerChats"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Show conveyancer chats
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="showAnyAppWithABrokerChat"
            checked={showAnyAppWithABrokerChat}
            onCheckedChange={(e) => setShowAnyAppWithABrokerChat(e === "indeterminate" ? false : e)}
          />
          <label
            htmlFor="showAnyAppWithABrokerChat"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Show any app with a broker chat
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="showBrokerChats"
            checked={showBrokerChats}
            onCheckedChange={(e) => setShowBrokerChats(e === "indeterminate" ? false : e)}
          />
          <label
            htmlFor="showBrokerChats"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Show broker chats
          </label>
        </div>

        <InfiniteAppQueryBadge
          className="ml-auto"
          count={count}
          isFetching={isFetching}
          dataUpdatedAt={dataUpdatedAt}
          handleRefetchClick={refetch}
          isPending={isPending}
          totalCount={filteredApps?.length}
        />
      </div>
      <div className="flex flex-row gap-6">
        <Tabs />
      </div>

      {_.uniqBy([...josh, ...appsWithConveyancerChats, ...appsWithBrokerChats, ...appsWithoutChats], "id")
        ?.filter(notUndefinedOrNull)
        ?.map((app) => (
          <TaskCard
            showBrokerChats={showBrokerChats}
            showConveyancerChats={showConveyancerChats}
            key={app.id}
            app={app}
            firstName={user?.firstName}
          />
        ))}
    </div>
  );
}

const TaskCard = ({
  app,
  firstName,
  showBrokerChats,
  showConveyancerChats,
}: { app: ChecklistApp; firstName?: string; showBrokerChats: boolean; showConveyancerChats: boolean }) => {
  const brokerRef = useRef<AppChatHandle>(null);

  const itemsForChecklist = app?.checklists?.[enums.ApplicationStatus.READY_FOR_INSTRUCTIONS]?.filter(
    (check) => !check.ok
  );
  const documentsForChecklist = itemsForChecklist
    ?.filter(types.isDocumentChecklistItem)
    ?.filter((x) => !x.document?.toLowerCase()?.includes("unsigned"));
  const documentsNotUploaded = documentsForChecklist?.filter((item) => !item.documentUrl);

  const onPokeBroker = useCallback(() => {
    brokerRef.current?.setText(
      `Hey there, I just wanted to check in on the status of the application. We're still waiting on the following documents:\n\n${documentsNotUploaded
        ?.map((x) => getChecklistItemNameString(x, "for").replaceAll("\n", " "))
        ?.filter((x) => Boolean(x?.trim()))
        ?.map((x) => `- ${x}`)
        .join("\n")}
        
Thanks!
${firstName}`
    );
  }, [app, firstName]);

  return (
    <div
      className="min-h-[550px] relative w-full bg-white px-4 py-2 border rounded border-gray-800 flex flex-col gap-2"
      key={app.id}
    >
      <div className="flex flex-row justify-between">
        <Link to={`/app/${app.id}`}>
          <b className="text-md">{formatters.application.applicantNames(app)}</b>
        </Link>
        <Badge className="rounded-full">{_.startCase(app.status)}</Badge>
      </div>
      <p className="text-sm font-semibold">Brokers: {formatters.application.brokerNames(app) || "None"}</p>
      <Button onClick={onPokeBroker} className="w-fit">
        Poke broker about outstanding documents
      </Button>
      <AppChecklistItems app={app} status={enums.ApplicationStatus.READY_FOR_INSTRUCTIONS} />
      <div className="absolute flex flex-row z-10 bottom-0 right-20 gap-x-2 items-end">
        {showConveyancerChats ? (
          <AppChat
            enableChatOnFocusOnly
            className="shadow w-[400px]"
            expandedClassName="h-[507px]"
            applicationId={app?.id}
            type={enums.ChatType.CONVEYANCER_EMPLOYEE}
            initialData={app?.fiveMostRecentConveyancerChats}
          />
        ) : null}
        {showBrokerChats ? (
          <AppChat
            ref={brokerRef}
            enableChatOnFocusOnly
            className="shadow w-[400px]"
            expandedClassName="h-[507px]"
            applicationId={app?.id}
            type={enums.ChatType.BROKER_EMPLOYEE}
            initialData={app?.fiveMostRecentBrokerChats}
          />
        ) : null}
      </div>
    </div>
  );
};
