import Color from "color";
import randomcolor from "randomcolor";
import { v5 as uuidv5 } from "uuid";

const reroll = (seed?: string) => (seed ? uuidv5(seed, "6ba7b810-9dad-11d1-80b4-00c04fd430c8") : seed);

export function getDarkColorFromSeed(seed?: string) {
  const color = Color(randomcolor({ seed, luminosity: "bright" })).darken(0.25);
  if (color.isDark()) {
    return color.hex();
  }
  return Color(randomcolor({ seed: reroll(seed), luminosity: "dark" }))
    .darken(0.25)
    .hex();
}
