import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Saved, calculators, entities, enums, formatters } from "@fraction/shared";
import { formatDistanceToNow } from "date-fns";
import _ from "lodash";
import { EyeIcon, Loader, Trash2Icon } from "lucide-react";
import { forwardRef, useCallback, useState } from "react";
import fraction from "src/api/fraction";
import { useDocumentsQuery } from "src/apps/PostFundedDashboard/queries";
import { Skeleton } from "src/components";
import { DocumentApprovalButtons } from "src/components/DocumentApproval";
import { KeyValue } from "src/components/KeyValue";
import { PDFViewer } from "src/components/PDFViewer";
import { StatusIconWithText } from "src/embeds/Classifier/components/LabeledDocumentsList/LabeledDocumentItem";
import { useModal } from "src/hooks";
import { useMutation } from "src/lib";

export const ExistingDocumentList = ({
  applicationId,
  enabled,
  draggable,
  mutatable,
}: { applicationId?: string; enabled?: boolean; draggable?: boolean; mutatable?: boolean }) => {
  const { data, isLoading, refetch } = useDocumentsQuery(applicationId, { enabled });
  const [documentDeleting, setDocumentDeleting] = useState<string | null>(null);

  const { showModal, closeModal } = useModal();

  const deleteFile = useMutation({
    mutationFn: async (documentId: string) => {
      try {
        setDocumentDeleting(documentId);
        await fraction.deleteFile(documentId);
      } catch (err) {
        setDocumentDeleting(null);
        throw err;
      }
    },
    onSuccess: async () => {
      closeModal();
      await refetch();
      setDocumentDeleting(null);
    },
  });

  const handleClickDelete = useCallback((document: Saved<entities.UploadedFile>) => {
    showModal({
      closeButton: true,
      closeOnClickBackdrop: true,
      message: (
        <>
          Are you sure you want to delete <b>{document?.type?.fullName}</b>?
        </>
      ),
      actions: [
        {
          text: "Cancel",
          type: "inverse",
          action: () => {
            closeModal();
          },
        },
        {
          text: "Delete",
          type: "urgent",
          action: () => {
            closeModal();
            deleteFile.mutate(document.id);
          },
        },
      ],
    });
  }, []);

  const handleClickView = useCallback((document: Saved<entities.UploadedFile>) => {
    if (document.s3Document?.href) {
      showModal({
        shovable: true,
        children: (
          <PDFViewer
            renderTextLayer
            filename={`${document.type?.fullName}.pdf`}
            toolbar="footer"
            download
            pdfClassName="h-[700px]"
            url={document.s3Document?.href}
          />
        ),
      });
    }
  }, []);

  if (!applicationId) {
    return null;
  }

  const sorted = _.sortBy(data, (doc) => doc.updatedAt).reverse();
  const needsWorkDocs = sorted?.filter(
    (doc) => doc.status === enums.ApplicationTaskApprovalStatus.NEEDS_WORK
  );
  const otherDocs = sorted?.filter((doc) => doc.status !== enums.ApplicationTaskApprovalStatus.NEEDS_WORK);
  const docs = [...(needsWorkDocs || []), ...(otherDocs || [])];

  return (
    <div className="bg-gray-100 h-full rounded p-3 flex flex-col gap-2 overflow-y-scroll overflow-x-hidden overscroll-contain">
      {isLoading
        ? Array.from({ length: 20 }).map((_, i) => <Skeleton className="h-[138px]" key={i} />)
        : null}
      {docs?.map((document) => (
        <ExistingDocumentItem
          draggable={draggable}
          key={document.id}
          document={document}
          onClickDelete={handleClickDelete}
          onClickView={handleClickView}
          deleting={documentDeleting === document.id}
          mutatable={mutatable}
        />
      ))}
    </div>
  );
};

export const ExistingDocumentItem = ({
  document,
  onClickDelete,
  onClickView,
  deleting,
  draggable,
  mutatable,
}: {
  document: Saved<entities.UploadedFile>;
  onClickDelete?: (doc: Saved<entities.UploadedFile>) => void;
  onClickView?: (doc: Saved<entities.UploadedFile>) => void;
  deleting?: boolean;
  draggable?: boolean;
  mutatable?: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    disabled: !draggable,
    id: document.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const draggableAttributes = draggable ? attributes : {};

  return (
    <ExistingDocumentItemPresentational
      document={document}
      ref={setNodeRef}
      style={style}
      onClickDelete={onClickDelete}
      onClickView={onClickView}
      deleting={deleting}
      mutatable={mutatable}
      {...listeners}
      {...draggableAttributes}
    />
  );
};

export const ExistingDocumentItemPresentational = forwardRef(
  (
    {
      document,
      onClickDelete,
      onClickView,
      deleting,
      mutatable,
      ...props
    }: {
      document: Saved<entities.UploadedFile>;
      onClickDelete?: (doc: Saved<entities.UploadedFile>) => void;
      onClickView?: (doc: Saved<entities.UploadedFile>) => void;
      deleting?: boolean;
      mutatable?: boolean;
    } & any,
    ref
  ) => {
    if (!document) {
      return null;
    }

    const handleClickDelete = useCallback(() => {
      onClickDelete?.(document);
    }, [onClickDelete, document?.id]);

    const handleClickView = useCallback(() => {
      onClickView?.(document);
    }, [onClickView, document?.id]);

    return (
      <div
        ref={ref}
        className="border rounded border-gray-300 p-2 gap-2 flex flex-col bg-white z-[100]"
        key={document.id}
        {...props}
      >
        <div className="flex flex-row items-center justify-between gap-1">
          <div className="flex flex-row items-center gap-2 truncate">
            <StatusIconWithText status={document.status || enums.ApplicationTaskApprovalStatus.REQUESTED} />
            <p
              title={`${document.type?.fullName} ${
                document.applicants?.length
                  ? `for ${document.applicants
                      .map((applicant: entities.ApplicantT) =>
                        applicant?.user ? formatters.user.userName(applicant?.user) : ""
                      )
                      .join(", ")}`
                  : ""
              }
              ${document.debts?.length ? ` for ${formatters.debt.formatDebtName(document.debts[0])}` : ""}`}
              className="text-sm truncate"
            >
              <b>{document.type?.fullName}</b>{" "}
              {document.applicants?.length
                ? `for ${document.applicants
                    .map((applicant: entities.ApplicantT) =>
                      applicant?.user ? formatters.user.userName(applicant?.user) : ""
                    )
                    .join(", ")}`
                : ""}
              {document.debts?.length ? ` for ${formatters.debt.formatDebtName(document.debts[0])}` : ""}
            </p>
          </div>
          <div className="flex flex-row gap-1">
            {mutatable && handleClickDelete ? (
              <button onClick={handleClickDelete}>
                {deleting ? (
                  <Loader height={26} className="text-gray-600 animate-spin" />
                ) : (
                  <Trash2Icon
                    height={26}
                    width={26}
                    className="hover:bg-red-100 rounded p-1 text-red min-h-[26px] min-w-[26px]"
                  />
                )}
              </button>
            ) : null}
            {onClickView && document?.s3Document?.href ? (
              <button onClick={handleClickView}>
                <EyeIcon
                  height={26}
                  width={26}
                  className="hover:bg-gray-100 rounded p-1 text-gray min-h-[26px] min-w-[26px]"
                />
              </button>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col [&>*]:rounded [&>*]:px-2 [&>*]:py-1 [&>*:nth-child(odd)]:bg-gray-200 [&>*:nth-child(even)]:white hover:[&>*]:bg-gray-300">
          <KeyValue
            className="px-1 py-0"
            label="Date uploaded"
            value={formatDistanceToNow(document.createdAt, { addSuffix: true })}
          />
          <KeyValue
            className="px-1 py-0"
            label="Document date"
            value={formatters.date.formatDate(document.date || document.createdAt)}
          />
          {document?.debts?.length ? (
            <KeyValue
              className="px-1 py-0"
              label="Debts"
              value={document.debts
                .map(
                  (debt: entities.Debt) =>
                    `${formatters.debt.formatFriendlyDebtName(debt)} ${
                      debt?.payingOffWithProceeds ? "[To payoff]" : ""
                    }`
                )
                .join(", ")}
            />
          ) : null}
          {document?.incomes?.length ? (
            <KeyValue
              className="px-1 py-0"
              label="Incomes"
              value={document.incomes
                .map(
                  (income: entities.Income) =>
                    `${
                      income?.jobTitle || income?.description || income?.type
                    } | ${formatters.number.getCurrencyWords(
                      income?.payment && calculators.payment.convertPaymentToAnnualAmount(income?.payment),
                      { short: true, thousandsRemainder: 1 }
                    )}`
                )
                .join(", ")}
            />
          ) : null}
          {document?.applicants?.length ? (
            <KeyValue
              className="px-1 py-0"
              label="Applicants"
              value={document.applicants
                .map((applicant: entities.ApplicantT) =>
                  applicant?.user ? formatters.user.userName(applicant?.user) : ""
                )
                .join(", ")}
            />
          ) : null}
        </div>
        {document.notes ? (
          <p className="bg-gray-400 p-2 text-sm rounded">
            <b>Notes</b>
            <br />
            {document.notes}
          </p>
        ) : null}
        {mutatable ? (
          <div className="flex flex-row justify-end gap-1 bg-white">
            <DocumentApprovalButtons file={document} />
          </div>
        ) : null}
      </div>
    );
  }
);
