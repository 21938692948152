import { enums, formatters, isStatusBeforeStatus, utilities } from "@fraction/shared";
import { formatDistanceToNow, isBefore, isSameDay } from "date-fns";
import { Clock } from "lucide-react";
import { Route, Routes, useParams } from "react-router-dom";
import { CustomLabelRequestedCloseDateModifiableKeyValue } from "src/apps/PreFundedDashboard/components/CloseDateModifiableKeyValue";
import { AppChat } from "src/components/AppChat";
import { AppChatContainer } from "src/components/AppChatContainer";
import { AppDetailsOverview } from "src/components/AppDetailsOverview";
import { AppDocumentCreation } from "src/components/AppDocumentCreation";
import { AppDocumentList } from "src/components/AppDocumentList";
import { AppOverviewShell } from "src/components/AppOverviewShell";
import { OutstandingDocumentsToGenerate } from "src/components/OutstandingDocumentsToGenerate";
import { Alert, AlertDescription, AlertTitle } from "src/components/ui/alert";
import { useApplicationAuthed } from "src/hooks/useApplication";

/**
 * inner tabs w/ routes
 */
export function BrokerAppOverviewShell() {
  const { id } = useParams();

  const { data: app, isLoading: loading } = useApplicationAuthed({
    id,
    initialRefetch: true,
  });

  return (
    <>
      <AppOverviewShell app={app || undefined} loading={loading} tabs={["overview", "doc-list"]} />
      <AppChatContainer loading={loading}>
        {!app?.lenderId || app.lenderId === enums.LenderName.FRACTION ? (
          <AppChat
            anonymous
            title="Chat with Fraction about this deal"
            textBoxClassName="h-[400px]"
            className="shadow"
            applicationId={id}
            type={enums.ChatType.BROKER_EMPLOYEE}
          />
        ) : (
          <AppChat
            anonymous
            title="Chat with Fraction about this deal"
            textBoxClassName="h-[400px]"
            className="shadow"
            applicationId={id}
            type={enums.ChatType.LENDER_BROKER_EMPLOYEE}
          />
        )}
      </AppChatContainer>
    </>
  );
}

const ClosingDateAlert = ({ applicationId }: { applicationId?: string }) => {
  const { data: app, isLoading } = useApplicationAuthed({
    id: applicationId,
  });

  if (isLoading) {
    return null;
  }

  if (
    app?.status &&
    [
      enums.ApplicationStatus.FUNDED,
      enums.ApplicationStatus.HOMEOWNER_RESCINDED,
      enums.ApplicationStatus.REJECTED,
    ].includes(app?.status)
  ) {
    return null;
  }

  if (!app) {
    return null;
  }

  if (app.lenderId !== enums.LenderName.FRACTION) {
    return null;
  }

  const closeDate = app?.requestedCloseDate;

  if (!closeDate) {
    return (
      <Alert>
        <Clock className="h-4 w-4" />
        <AlertTitle className="font-bold">Closing date is not set</AlertTitle>
        <AlertDescription>Please request a close date for this mortgage.</AlertDescription>
      </Alert>
    );
  }

  const closeDateWithTime = utilities.bankHolidays.setTo4pmEST(closeDate);

  const needToReceiveSignedDocsBy = utilities.bankHolidays.getNeedsToReceiveSignedDocsBy(closeDateWithTime);
  const needToBeBrokerCompleteBy = utilities.bankHolidays.getNeedsToBeBrokerCompleteBy(closeDateWithTime);

  if (
    isSameDay(new Date(closeDateWithTime), new Date()) ||
    isBefore(new Date(closeDateWithTime), new Date())
  ) {
    return (
      <Alert className="bg-red/20" variant="destructive">
        <Clock className="h-4 w-4" />
        <AlertTitle className="font-bold">Closing date is in the past</AlertTitle>
        <AlertDescription>
          Please request a new close date for this mortgage.
          <div className="w-fit border-red-400 bg-gray-200 border rounded-md p-0 mt-2 -ml-8">
            <div className="w-fit">
              <CustomLabelRequestedCloseDateModifiableKeyValue
                label="Request a new close date"
                app={app}
                loading={isLoading}
              />
            </div>
          </div>
        </AlertDescription>
      </Alert>
    );
  }

  if (isBefore(new Date(needToReceiveSignedDocsBy), new Date())) {
    return (
      <Alert className="bg-orange/20" variant="destructive">
        <Clock className="h-4 w-4" />
        <AlertTitle className="font-bold">
          Closing date is {formatDistanceToNow(new Date(closeDateWithTime), { addSuffix: true })} on{" "}
          {formatters.date.formatDateNoYear(closeDateWithTime)}
        </AlertTitle>
        <AlertDescription>
          Fraction needed to receive all documents signed from the client's lawyer by{" "}
          <b>{formatters.date.formatDateNoYear(needToReceiveSignedDocsBy)} on 4:00pm EST</b>. If this did not
          happen, you will need to choose a new close date in the future.
          <div className="w-fit border-red-400 bg-gray-200 border rounded-md p-0 mt-2 -ml-8">
            <div className="w-fit">
              <CustomLabelRequestedCloseDateModifiableKeyValue
                label="Request a new close date"
                app={app}
                loading={isLoading}
              />
            </div>
          </div>
        </AlertDescription>
      </Alert>
    );
  }

  // before conveyancer engaged means it is not broker complete
  if (app?.status && isStatusBeforeStatus(app.status, enums.ApplicationStatus.CONVEYANCER_ENGAGED)) {
    if (isBefore(new Date(needToBeBrokerCompleteBy), new Date())) {
      return (
        <Alert className="bg-orange/20" variant="destructive">
          <Clock className="h-4 w-4" />
          <AlertTitle className="font-bold">
            Closing date is {formatDistanceToNow(new Date(closeDateWithTime), { addSuffix: true })} on{" "}
            {formatters.date.formatDateNoYear(closeDateWithTime)}
          </AlertTitle>
          <AlertDescription>
            The file needed to be broker complete{" "}
            <b>
              {formatDistanceToNow(needToBeBrokerCompleteBy, {
                addSuffix: true,
              })}{" "}
              on {formatters.date.formatDateNoYear(needToBeBrokerCompleteBy)} by 4:00pm EST.
            </b>{" "}
            Please request a new close date.
            <div className="w-full bg-gray-200 rounded-md p-0 mt-2 -ml-8">
              <div className="w-fit">
                <CustomLabelRequestedCloseDateModifiableKeyValue
                  label="Request a new close date"
                  app={app}
                  loading={isLoading}
                />
              </div>
            </div>
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <Alert>
        <Clock className="h-4 w-4" />
        <AlertTitle className="font-bold">
          Closing date is {formatDistanceToNow(new Date(closeDateWithTime), { addSuffix: true })} on{" "}
          {formatters.date.formatDateNoYear(closeDateWithTime)}
        </AlertTitle>
        <AlertDescription>
          The file needs to be broker complete{" "}
          <b>
            {formatDistanceToNow(needToBeBrokerCompleteBy, {
              addSuffix: true,
            })}{" "}
            on {formatters.date.formatDateNoYear(needToBeBrokerCompleteBy)} by 4:00pm EST.
          </b>{" "}
          <div className="w-full bg-gray-200 rounded-md p-0 mt-2 -ml-8">
            <div className="w-fit">
              <CustomLabelRequestedCloseDateModifiableKeyValue
                label="Request a new close date"
                app={app}
                loading={isLoading}
              />
            </div>
          </div>
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Alert>
      <Clock className="h-4 w-4" />
      <AlertTitle className="font-bold">
        Closing date is {formatDistanceToNow(new Date(closeDateWithTime), { addSuffix: true })} on{" "}
        {formatters.date.formatDateNoYear(closeDateWithTime)}
      </AlertTitle>
      <AlertDescription>
        Please ensure that Fraction receives all required signed documents from the client's lawyer{" "}
        <b>
          {formatDistanceToNow(needToReceiveSignedDocsBy, {
            addSuffix: true,
          })}{" "}
          on {formatters.date.formatDateNoYear(needToReceiveSignedDocsBy)} by 4:00pm EST
        </b>{" "}
        or we will not be able to fund on {formatters.date.formatDateNoYear(closeDateWithTime)}.
        <div className="w-full bg-gray-200 rounded-md p-0 mt-2 -ml-8">
          <div className="w-fit">
            <CustomLabelRequestedCloseDateModifiableKeyValue
              label="Request a new close date"
              app={app}
              loading={isLoading}
            />
          </div>
        </div>
      </AlertDescription>
    </Alert>
  );
};

export function AppDetailsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BrokerAppOverviewShell />}>
        <Route
          path="/"
          element={
            <AppDetailsOverview>
              <ClosingDateAlert />
              <OutstandingDocumentsToGenerate />
            </AppDetailsOverview>
          }
        />
        <Route path="/docs" element={<AppDocumentCreation />} />
        <Route path="/doc-list" element={<AppDocumentList />} />
      </Route>
    </Routes>
  );
}
