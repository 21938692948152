import { formatDistanceToNow } from "date-fns";
import { Loader } from "lucide-react";
import { RefreshCwIcon } from "lucide-react";
import { Badge } from "src/components/ui/badge";
import { cn } from "src/utilities/shadcnUtils";

export function InfiniteAppQueryBadge({
  count,
  isFetching,
  dataUpdatedAt,
  totalCount,
  handleRefetchClick,
  isPending,
  className,
}: {
  count?: number;
  isFetching?: boolean;
  dataUpdatedAt?: number;
  totalCount?: number;
  handleRefetchClick?: () => void;
  isPending?: boolean;
  className?: string;
}) {
  return (
    <button className={className} onClick={handleRefetchClick}>
      <Badge
        loading={totalCount === undefined}
        className="group bg-gray-400 hover:bg-gray-500 text-black rounded-full h-7"
      >
        {isFetching || isPending ? (
          <Loader height={18} className={cn("text-gray-600 animate-spin mr-1", isPending && "text-blue")} />
        ) : (
          <RefreshCwIcon height={16} className="text-gray-600 mr-1 group-hover:block hidden" />
        )}{" "}
        {totalCount || 0} of {count || 0} apps loaded
        {dataUpdatedAt ? (
          <p className="text-xs text-black font-light ml-1">
            updated {formatDistanceToNow(new Date(dataUpdatedAt), { addSuffix: true })}
          </p>
        ) : null}
      </Badge>
    </button>
  );
}
