import { constants, calculators, colors, formatters } from "@fraction/shared";
import { PrinterIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { TermLengthSelect } from "src/apps/PortfolioDashboard/components/Filter/TermLengthSelect";
import { computeCostCalculatorRows } from "src/calculators/cost-calculator";
import {
  BasicTable,
  BasicTableCell,
  BasicTableHeader,
  BasicTableRow,
  Skeleton,
  TextInput,
  Touchable,
} from "src/components";
import HelpCircle from "src/components/HelpCircle";
import { CURRENCY_MASK, PERCENTAGE_MASK } from "src/components/TextInput";
import { RegularText } from "src/components/v1";
import { useCurrentRate, useQueryParams } from "src/hooks";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

const styles = createStyles({
  cell: {
    fontSize: 14,
  },
  spanInput: {
    padding: 2,
    width: 105,
    minWidth: "unset",
  },
  narrow: {
    width: 30,
  },
  spanInputContainer: {
    width: "min-content",
    display: "inline-flex",
  },
  explainerText: {
    maxWidth: 500,
    textAlign: "center",
  },
  tooltip: {
    marginBottom: 3,
  },
  tooltipText: {
    minWidth: 350,
  },
});

const HIDDEN_COLUMNS = {
  apr: true,
};

const CostTable = ({
  rate,
  loanAmount,
  defaultRate,
  homeValue,
  appreciationRate,
  showApr,
  termLength,
}: {
  rate?: number;
  loanAmount: number;
  defaultRate?: number;
  homeValue?: number;
  appreciationRate?: number;
  showApr?: boolean;
  termLength?: number;
}) => {
  const rows = computeCostCalculatorRows({
    rate: rate || defaultRate || 0,
    loanAmount,
    homeValue,
    appreciationRate,
    termLength,
  });

  return (
    <div className="overflow-x-scroll max-w-full">
      <BasicTable>
        <thead>
          <BasicTableRow>
            <BasicTableHeader variant="dense" textStyle={styles.cell}>
              Year
            </BasicTableHeader>
            <BasicTableHeader variant="dense" textStyle={styles.cell}>
              Total Accrued Interest
            </BasicTableHeader>
            <BasicTableHeader variant="dense" textStyle={styles.cell}>
              Total Loan Balance
            </BasicTableHeader>
            <BasicTableHeader treatAsText variant="dense" textStyle={styles.cell}>
              Home Value
              <HelpCircle style={styles.tooltip}>
                <RegularText>
                  Given a yearly appreciation rate of{" "}
                  <b>{formatters.number.getPercentageFromDecimal(appreciationRate || 0.06)}</b>.
                </RegularText>
              </HelpCircle>
            </BasicTableHeader>
            <BasicTableHeader variant="dense" textStyle={styles.cell}>
              Your Equity
            </BasicTableHeader>
            {!HIDDEN_COLUMNS.apr || showApr ? (
              <BasicTableHeader treatAsText variant="dense" textStyle={styles.cell}>
                APR{" "}
                <HelpCircle style={styles.tooltip}>
                  <RegularText>
                    APR gives you a comprehensive view of the total cost of a loan, and includes the interest
                    rate as well as any additional fees associated with the loan. <br />
                    <br />
                    For Fraction, the fees include the origination fee <b>3%</b>, or{" "}
                    <b>
                      {formatters.number.getCurrencyFromNumber(loanAmount * constants.ORIGINATION_FEE, {
                        inputInDollars: true,
                      })}
                    </b>
                    ), a conveyancing fee of{" "}
                    <b>{formatters.number.getCurrencyFromNumber(constants.CONVEYANCING_FEE)}</b>, and a title
                    insurance fee of{" "}
                    <b>{formatters.number.getCurrencyFromNumber(constants.TITLE_INSURANCE_FEE)}</b>.
                  </RegularText>
                </HelpCircle>
              </BasicTableHeader>
            ) : null}
          </BasicTableRow>
        </thead>
        <tbody>
          {rows.map((row) => (
            <BasicTableRow>
              <BasicTableCell variant="dense" textStyle={styles.cell}>
                {row.year.toString()}
              </BasicTableCell>
              <BasicTableCell variant="dense" textStyle={styles.cell}>
                {formatters.number.getCurrencyFromNumber(row.totalAccruedInterest, {
                  inputInDollars: false,
                })}
              </BasicTableCell>
              <BasicTableCell variant="dense" textStyle={styles.cell}>
                {formatters.number.getCurrencyFromNumber(row?.totalLoanBalance, { inputInDollars: false })}
              </BasicTableCell>
              <BasicTableCell variant="dense" textStyle={styles.cell}>
                {formatters.number.getCurrencyFromNumber(row?.homeValueAtYear, { inputInDollars: false })}
              </BasicTableCell>
              <BasicTableCell variant="dense" textStyle={styles.cell}>
                {formatters.number.getCurrencyFromNumber(row?.homeValueAtYear - row?.totalLoanBalance, {
                  inputInDollars: false,
                })}
              </BasicTableCell>
              {!HIDDEN_COLUMNS.apr || showApr ? (
                <BasicTableCell variant="dense" textStyle={styles.cell}>
                  {typeof row.apr === "string"
                    ? row.apr
                    : formatters.number.getPercentageFromDecimal(row.apr)}
                </BasicTableCell>
              ) : null}
            </BasicTableRow>
          ))}
        </tbody>
      </BasicTable>
    </div>
  );
};

const CostCalculator = ({
  className,
  forBrokers: forBrokers_,
}: { className?: string; forBrokers?: boolean }) => {
  const [term, setTerm] = useState(constants.DEFAULT_TERM_LENGTH);
  const { rate: defaultRate, isLoading } = useCurrentRate("Ontario", {
    termLength: term as 3 | 4 | 5 | 1,
  });
  const { rate: paramRate, forBrokers: forBrokersParam } = useQueryParams();
  const paramRateNumber = Number(paramRate);

  const forBrokers = forBrokers_ || forBrokersParam;

  const [appreciationRate, setAppreciationRate] = useState("6");
  const [homeValue, setHomeValue] = useState("1000000");

  const [rate, setRate] = useState(
    (Number.isNaN(paramRateNumber) ? undefined : paramRateNumber.toString()) ||
      (defaultRate?.minimumRate ? (defaultRate.minimumRate * 100).toString() : undefined)
  );

  useEffect(() => {
    if (defaultRate?.minimumRate) {
      setRate((defaultRate.minimumRate * 100).toString());
    }
  }, [defaultRate?.minimumRate]);

  const componentToPrintRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const [loanAmount, setLoanAmount] = useState("350000");

  const loanValueAfterTerm = calculators.rates
    .calculateLoanValueAfterTerm({
      annualRate: Number(rate) / 100 || defaultRate?.minimumRate || 0,
      termLength: term,
      totalLoanValue: formatters.number.getNumberFromString(loanAmount),
    })
    .toDecimalPlaces(2)
    .toNumber();

  const homeValueEndOfTerm =
    formatters.number.getNumberFromString(homeValue) *
    (1 + formatters.number.getNumberFromString(appreciationRate || 6) / 100) ** term;

  if (!rate && isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Skeleton height={500} width={700} />
      </div>
    );
  }

  return (
    <div ref={componentToPrintRef} className={cn("p-5 flex-col flex items-center gap-4", className)}>
      <div className="flex flex-row gap-2 flex-wrap justify-center">
        <TextInput
          errorSpacing={false}
          maskOptions={CURRENCY_MASK}
          label="Loan amount"
          placeholder="$350,000"
          defaultValue={loanAmount}
          value={loanAmount}
          onChange={setLoanAmount}
          variant="v2"
        />
        <TextInput
          errorSpacing={false}
          value={rate?.toString()}
          maskOptions={PERCENTAGE_MASK}
          label="Interest rate"
          onChange={setRate}
          placeholder={defaultRate?.minimumRate ? (defaultRate?.minimumRate * 100)?.toString() : undefined}
          defaultValue={defaultRate?.minimumRate ? (defaultRate?.minimumRate * 100)?.toString() : undefined}
          variant="v2"
        />
        {forBrokers ? (
          <Touchable onClick={handlePrint}>
            <PrinterIcon className="print:hidden" color={colors.palette.GREY_800} />
          </Touchable>
        ) : null}
      </div>
      <CostTable
        showApr={forBrokers}
        homeValue={formatters.number.getCents(homeValue)}
        appreciationRate={formatters.number.getNumberFromString(appreciationRate) / 100}
        rate={Number(rate)}
        defaultRate={defaultRate?.minimumRate}
        loanAmount={formatters.number.getCents(loanAmount)}
        termLength={term}
      />
      <div>
        <RegularText style={styles.explainerText}>
          If you originally borrowed{" "}
          <b>
            {formatters.number.getCurrencyFromNumber(loanAmount, {
              inputInDollars: true,
            })}
          </b>{" "}
          from Fraction with a{" "}
          <TextInput
            errorSpacing={false}
            inputStyle={styles.spanInput}
            style={styles.spanInputContainer}
            variant="v2"
            maskOptions={CURRENCY_MASK}
            defaultValue={homeValue}
            onChange={setHomeValue}
            value={homeValue}
            placeholder="home value"
          />{" "}
          house, in{" "}
          <TermLengthSelect
            showOnlyOurProducts
            hideLabel
            onValueChange={setTerm}
            value={term}
            className="w-30 inline-flex font-montserrat"
          />{" "}
          time, your house will be worth around{" "}
          <b>
            {formatters.number.getCurrencyFromNumber(homeValueEndOfTerm, {
              inputInDollars: true,
            })}
          </b>{" "}
          based on a yearly appreciation of{" "}
          <TextInput
            errorSpacing={false}
            inputStyle={[styles.spanInput, styles.narrow]}
            style={styles.spanInputContainer}
            variant="v2"
            maskOptions={PERCENTAGE_MASK}
            defaultValue={appreciationRate}
            value={appreciationRate}
            placeholder="rate"
            onChange={setAppreciationRate}
          />
          . You would then owe{" "}
          <b>{formatters.number.getCurrencyFromNumber(loanValueAfterTerm, { inputInDollars: true })}</b> and
          own{" "}
          <b>
            {formatters.number.getCurrencyFromNumber(Math.max(homeValueEndOfTerm - loanValueAfterTerm, 0), {
              inputInDollars: true,
            })}
          </b>{" "}
          of your home.
        </RegularText>
      </div>
    </div>
  );
};

export default CostCalculator;
