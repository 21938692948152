import { create } from "zustand";

interface TimerStore {
  countdown: number | undefined;
  setCountdown: (countdown: number | undefined) => void;
  counterInterval: NodeJS.Timeout | undefined;
  decrementCountdown: (onFinishCallback: () => void) => void;
  setCounterInterval: (counterInterval: NodeJS.Timeout | undefined) => void;

  startCountdown: (onFinishCallback: () => void, startCountdownAt?: number) => void;
}

export const useTimers = create<TimerStore>((set) => ({
  countdown: undefined,
  setCountdown: (countdown: number | undefined) => set(() => ({ countdown })),
  decrementCountdown: (onFinishCallback: () => void) =>
    set((prev) => {
      if (prev.countdown !== undefined && prev.countdown === 0) {
        onFinishCallback();
      }
      return {
        countdown: prev.countdown !== undefined ? prev.countdown - 1 : undefined,
      };
    }),
  counterInterval: undefined,
  setCounterInterval: (counterInterval: NodeJS.Timeout | undefined) => {
    return set((prev) => {
      if (counterInterval === undefined) {
        clearInterval(prev.counterInterval);
      }
      return { counterInterval };
    });
  },
  startCountdown: (onFinishCallback: () => void, startCountdownAt: number = 30) => {
    set((prev) => {
      if (prev.counterInterval) {
        prev.setCounterInterval(undefined);
      }
      return {
        countdown: startCountdownAt,
        counterInterval: setInterval(() => {
          prev.decrementCountdown(onFinishCallback);
        }, 1000),
      };
    });
  },
}));
