import { useEffect, useRef, useState } from "react";
import useWebsocket, { ReadyState } from "react-use-websocket";
import { useAuth } from "src/auth";
import config from "src/config";

export function useAuthenticatedWebsocket({ routeKey, enabled }: { routeKey?: string; enabled?: boolean }) {
  const { user, token } = useAuth();
  const didUnmount = useRef(false);

  const {
    sendJsonMessage: sendMessageWebsocket,
    lastJsonMessage,
    readyState,
  } = useWebsocket(
    config.websocketURL,
    {
      shouldReconnect: () => !didUnmount.current,
    },
    enabled
  );
  const [lastMessage, setLastMessage] = useState<any>(null);

  useEffect(() => {
    didUnmount.current = false;
    if (!user?.id || readyState !== ReadyState.OPEN || !routeKey) {
      return;
    }
    sendMessageWebsocket({
      token,
    });
    return () => {
      didUnmount.current = true;
    };
  }, [user?.id, readyState, routeKey]);

  useEffect(() => {
    if (lastJsonMessage && typeof lastJsonMessage === "object" && "routeKey" in lastJsonMessage) {
      if (lastJsonMessage.routeKey === routeKey) {
        console.log("Message from keyed into websocket", routeKey, lastJsonMessage);
        setLastMessage(lastJsonMessage);
      } else if (lastJsonMessage.routeKey === "root") {
        console.log("Message from websocket root", lastJsonMessage);
      }
    }
  }, [lastJsonMessage]);

  // todo - if we want to send other messages, we should attach the routeKey to that message. But for now, we don't need that.
  return { lastMessage, readyState };
}
