import { SyntheticApplicationStatus, enums } from "@fraction/shared";
import _ from "lodash";
import Chip, { Variant } from "src/components/Chip";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/utilities/shadcnUtils";

export enum SimpleStatus {
  RequiresMoreInformation = "Requires more information",
  Underwriting = "Underwriting",
  DraftingCommitmentLetter = "Drafting commitment letter",
  CommitmentLetterSent = "Commitment letter sent",
  CollectingDocuments = "Collecting documents",
  EngagingConveyancer = "Sent to lawyer",
  Funded = "Funded",
  Rescinded = "Rescinded",
  Rejected = "Rejected",
  ExpiredTerms = "Expired terms",
}

export const SIMPLE_STATUS_ORDER_MAP = {
  [SimpleStatus.RequiresMoreInformation]: [
    enums.ApplicationStatus.PARTIALLY_COMPLETED,
    enums.ApplicationStatus.MISSING_INFORMATION,
  ],
  [SimpleStatus.Underwriting]: [
    enums.ApplicationStatus.UNDERWRITING,
    enums.ApplicationStatus.SUBMITTED_TO_WHITELABEL_LENDER,
    enums.ApplicationStatus.PENDING_DECLINE,
  ],
  [SimpleStatus.DraftingCommitmentLetter]: [
    enums.ApplicationStatus.APPROVED_FOR_COMMITMENT_LETTER,
    enums.ApplicationStatus.QUALIFIED_APPLICATION,
  ],
  [SimpleStatus.CommitmentLetterSent]: [enums.ApplicationStatus.COMMITMENT_LETTER_SENT],
  [SimpleStatus.CollectingDocuments]: [
    enums.ApplicationStatus.RECEIVING_DOCS,
    enums.ApplicationStatus.COMMITTED,
    enums.ApplicationStatus.INSPECTION_AND_APPRAISAL_COMPLETED,
    enums.ApplicationStatus.READY_FOR_INSTRUCTIONS,
    enums.ApplicationStatus.PARTIAL_DOCS_RECEIVED,
    enums.ApplicationStatus.WAITING_FOR_CLIENT,
  ],
  [SimpleStatus.EngagingConveyancer]: [
    enums.ApplicationStatus.CAPITAL_CALLED,
    enums.ApplicationStatus.READY_FOR_DRAW,
    enums.ApplicationStatus.CONVEYANCER_ENGAGED,
    enums.ApplicationStatus.READY_FOR_CAPITAL_CALL,
    SyntheticApplicationStatus.CONVEYANCER_REACHED_OUT_TO_ILR,
    SyntheticApplicationStatus.INSTRUCTIONS_SENT_TO_ILR,
    SyntheticApplicationStatus.PENDING_RECEIPT_OF_PAYMENT,
  ],
  [SimpleStatus.Funded]: [enums.ApplicationStatus.FUNDED],
  [SimpleStatus.Rescinded]: [enums.ApplicationStatus.HOMEOWNER_RESCINDED],
  [SimpleStatus.Rejected]: [enums.ApplicationStatus.REJECTED],
  [SimpleStatus.ExpiredTerms]: [enums.ApplicationStatus.EXPIRED_TERMS],
};

export const SIMPLE_STATUS_ORDER = Object.keys(SIMPLE_STATUS_ORDER_MAP);

export const getCurrentStatusBlock = (status: SyntheticApplicationStatus) => {
  const idx = Object.entries(SIMPLE_STATUS_ORDER_MAP)?.findIndex(([label, block]) =>
    block?.find((item) => item === status)
  );

  return {
    idx,
    label: Object.keys(SIMPLE_STATUS_ORDER_MAP)[idx],
    block: Object.values(SIMPLE_STATUS_ORDER_MAP)[idx],
    color: STATUS_COLOR[Object.keys(SIMPLE_STATUS_ORDER_MAP)[idx]],
  };
};

export const STATUS_COLOR: Record<string, Variant> = {
  "Requires more information": Variant.BASE,
  Underwriting: Variant.YELLOW,
  Approved: Variant.YELLOW,
  "Collecting documents": Variant.YELLOW,
  "Engaging conveyancer": Variant.YELLOW,
  Funded: Variant.GREEN,
  Rescinded: Variant.RED,
  Rejected: Variant.RED,
  "Pending decline": Variant.RED,
};

export function AppStatusBadge({
  status,
  loading,
  className,
  appId,
}: { status?: enums.ApplicationStatus; loading?: boolean; className?: string; appId?: string }) {
  if (loading) {
    return <Skeleton className={cn("rounded-full inline-flex", className)} height={25} width={70} />;
  }

  return status ? (
    <Chip className={className} variant={getCurrentStatusBlock(status)?.color || "green"}>
      {getCurrentStatusBlock(status)?.label}
    </Chip>
  ) : null;
}

export function RawAppStatusBadge({
  status,
  loading,
  className,
}: { status?: SyntheticApplicationStatus; loading?: boolean; className?: string }) {
  if (loading) {
    return <Skeleton className={cn("rounded-full inline-flex", className)} height={25} width={70} />;
  }

  return status ? (
    <Chip className={className} variant={getCurrentStatusBlock(status)?.color || "green"}>
      {_.startCase(status)}
    </Chip>
  ) : null;
}
