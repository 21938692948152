import {
  DndContext,
  DragMoveEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Global, css } from "@emotion/react";
import {
  SyntheticApplicationStatus,
  SyntheticApplicationStatuses,
  UI_APPLICATION_STATUS_ORDER,
  UI_SYNTHETIC_APPLICATION_STATUS_ORDER,
  UI_SYNTHETIC_APPLICATION_STATUS_ORDER_NO_DEPRECATED,
  entities,
  enums,
  verify,
} from "@fraction/shared";
import { useVirtualizer } from "@tanstack/react-virtual";
import _ from "lodash";
import { ReactNode, useCallback, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { ChecklistApp } from "src/api/fraction";
import { PipelineDealCard } from "src/apps/LOS/components/PipelineDealCard";
import { PipelineStageContainer } from "src/apps/LOS/components/PipelineStageContainer";
import { useDraggable } from "src/apps/LOS/useDraggable";
import { getCheckName } from "src/apps/LOS/utils";
import { useMutateApplicationStatus } from "src/apps/PostFundedDashboard/queries";
import { useKeepScrollPositionOnPageNav } from "src/hooks/useKeepScrollPositionOnPageNav";
import { useMutation } from "src/lib";
import { cn } from "src/utilities/shadcnUtils";
import { SIMPLE_STATUS_ORDER } from "./AppStatusBadge";
import { InfiniteAppQueryBadge } from "./InfiniteAppQueryBadge";

export interface AppsPipelineViewProps {
  apps?: Partial<ChecklistApp>[];
  isFetching?: boolean;
  header?: ReactNode;
  modal?: ReactNode;
  handleRefetchClick?: () => void;
  totalCount?: number;
  dataUpdatedAt?: number;
  groupBy?: "status" | "syntheticStatus" | "loan.status" | "simpleStatus";
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  className?: string;
  visibleStages?: (SyntheticApplicationStatus | enums.LoanStatus | string)[];
  forceForStages?: SyntheticApplicationStatus[];
  showChecklist?: boolean;
  showSalesPerson?: boolean;
  showLenderName?: boolean;
  salesOptions?: entities.InternalEmployee[];
  startEmptyCollapsed?: boolean;
  draggable?: boolean;
}

const CONVEYANCER_SYNTHETIC_STAGES = [
  ...Object.values(SyntheticApplicationStatuses),
  enums.ApplicationStatus.CONVEYANCER_ENGAGED,
];

// The number of pixels from the edge of the viewport to start scrolling.
const EDGE_THRESHOLD = 150;
// The number of pixels to scroll when the draggable is near the edge of the viewport.
const EDGE_SCROLL_SPEED = 15;

export function AppsPipelineView({
  apps,
  isFetching,
  header,
  handleRefetchClick,
  totalCount,
  dataUpdatedAt,
  groupBy = "status",
  modal,
  setShowModal,
  className,
  visibleStages = UI_SYNTHETIC_APPLICATION_STATUS_ORDER_NO_DEPRECATED,
  forceForStages = CONVEYANCER_SYNTHETIC_STAGES,
  showChecklist = true,
  showSalesPerson,
  showLenderName,
  salesOptions,
  startEmptyCollapsed = false,
  draggable = true,
}: AppsPipelineViewProps) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const mutateStatus = useMutateApplicationStatus();
  const handleChangeStatus = useMutation({
    mutationFn: async ({ id, status }: { id: string; status: SyntheticApplicationStatus }) => {
      const app = apps?.find((app) => app.id === id);

      if (!app) {
        return;
      }

      // if the app is within one of these stages and it's moving to another one within this list, we can force
      const force =
        !!forceForStages?.find((s) => s === status) && !!forceForStages?.find((s) => s === app.status);

      if (
        verify.isEnum(enums.ApplicationStatus, status) &&
        !force &&
        app?.checklists?.[status]?.find((check) => !check.ok)
      ) {
        setShowModal?.({ id, status });
        return false;
      }

      await mutateStatus.mutateAsync({ id: id as string, status, force });
      return true;
    },
  });

  const statusList = (
    groupBy === "status"
      ? UI_APPLICATION_STATUS_ORDER
      : groupBy === "syntheticStatus"
      ? UI_SYNTHETIC_APPLICATION_STATUS_ORDER
      : groupBy === "simpleStatus"
      ? SIMPLE_STATUS_ORDER
      : visibleStages
  ).filter((x) => visibleStages.includes(x));

  const stages = useMemo(() => {
    const grouped = _.groupBy(apps, groupBy);
    return statusList.reduce((acc, status) => {
      if (!acc[status]) {
        acc[status] = [];
      }
      return acc;
    }, grouped);
  }, [apps, groupBy]);

  const { active, items, ...handlers } = useDraggable<SyntheticApplicationStatus, ChecklistApp>({
    items: stages,
    onChange: handleChangeStatus.mutateAsync,
  });

  const columnsRef = useRef<HTMLDivElement>(null);
  const { id } = useKeepScrollPositionOnPageNav("apps-pipeline-view", columnsRef);

  // copy-pasted this code from https://github.com/clauderic/dnd-kit/issues/1284
  const handleDragMove = useCallback(
    (event: DragMoveEvent): void => {
      const viewport = columnsRef.current;

      const {
        active: { rect },
      } = event;

      if (viewport && rect.current.translated) {
        const doc = window.document.documentElement;
        const draggable = rect.current.translated;

        // The viewport doesn't fill the entire document, so we need to account for that.
        const viewportX = doc.offsetWidth - viewport.offsetWidth;
        // This is useful to balance out the scroll threshold on both sides of the viewport.
        // Otherwise we're just calculating from the left side of the draggable, which, depending
        // on the width of the draggable, can make it feel like the viewport/scroll behaviour isn't very responsive.
        const draggableCenterPoint = draggable.width / 2;
        const draggableX = draggable.left + draggableCenterPoint - viewportX;

        // The following will scroll the viewport by EDGE_SCROLL_SPEED when the draggable is
        // within EDGE_THRESHOLD pixels of the edge of the viewport. It will also prevent
        // scrolling when there is no more scrollable area.
        if (draggableX < EDGE_THRESHOLD && viewport.scrollLeft > 0) {
          viewport.scrollBy(-EDGE_SCROLL_SPEED, 0);
        } else if (
          viewport.offsetWidth - draggableX < EDGE_THRESHOLD &&
          viewport.scrollLeft < viewport.scrollWidth - viewport.offsetWidth
        ) {
          viewport.scrollBy(EDGE_SCROLL_SPEED, 0);
        }
      }
    },
    [columnsRef.current]
  );

  const activeApp = apps?.find((app) => app.id === active?.id);
  const activeChecklist = active?.status
    ? verify.isEnum(enums.ApplicationStatus, active.status)
      ? activeApp?.checklists?.[active.status]
      : undefined
    : undefined;

  const noQualifiedApp = statusList?.filter((x) => x !== enums.ApplicationStatus.QUALIFIED_APPLICATION);

  const rowVirtualizer = useVirtualizer({
    count: noQualifiedApp.length,
    getScrollElement: () => columnsRef.current,
    estimateSize: () => 80,
    horizontal: true,
    getItemKey: (index) => noQualifiedApp[index],
    gap: 6,
    // The number of items to render above and below the visible area. Increasing this number will increase the amount of time it takes to render the virtualizer, but might decrease the likelihood of seeing slow-rendering blank items at the top and bottom of the virtualizer when scrolling.
    overscan: 4,
  });

  return (
    <>
      <Global
        styles={css`
        body {
          overscroll-behavior-x: none;
        }
      `}
      />
      <div className={cn("w-full", className)}>
        <div className="w-full justify-between flex pr-6 mb-3">
          {header ? header : <div />}
          <div>
            <InfiniteAppQueryBadge
              count={totalCount}
              isFetching={isFetching}
              dataUpdatedAt={dataUpdatedAt}
              handleRefetchClick={handleRefetchClick}
              isPending={handleChangeStatus.isPending}
              totalCount={apps?.length}
            />
          </div>
        </div>
        {/*// @ts-ignore*/}
        <DndContext
          // autoScroll doesn't work with our Kanban style layout
          // so for now, we're disabling it and rolling our own scroll behavior
          autoScroll={false}
          onDragMove={handleDragMove}
          sensors={sensors}
          collisionDetection={closestCorners}
          {...handlers}
        >
          <div id={id} ref={columnsRef} className="overflow-y-auto max-w-[98vw] w-[98vw] pb-5">
            <div
              css={{
                width: rowVirtualizer.getTotalSize(),
                height: "65svh",
                position: "relative",
              }}
              className="flex flex-row"
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <PipelineStageContainer
                  startEmptyCollapsed={startEmptyCollapsed}
                  draggable={draggable}
                  data-index={virtualItem.index}
                  ref={rowVirtualizer.measureElement}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    // width: `${virtualItem.size}px`,
                    transform: `translateX(${virtualItem.start}px)`,
                  }}
                  setShowModal={setShowModal}
                  key={virtualItem.key}
                  // @ts-ignore
                  id={virtualItem.key}
                  // @ts-ignore
                  items={items[virtualItem.key]}
                  showChecklist={showChecklist}
                  showSalesPerson={showSalesPerson}
                  showLenderName={showLenderName}
                  salesOptions={salesOptions}
                />
              ))}
            </div>
          </div>
          {modal}
          {createPortal(
            <DragOverlay>
              {active?.id ? (
                <PipelineDealCard
                  hideStatus={!showChecklist}
                  id={active?.id}
                  statusOverride={activeApp?.status}
                  syntheticStatusOverride={active?.status}
                  showLenderName={showLenderName}
                  showSalesPerson={showSalesPerson}
                >
                  {activeChecklist?.length ? (
                    <div>
                      <b className="text-sm">Required fields</b>
                      {activeChecklist
                        ?.filter((check) => !check.ok)
                        ?.map((check) => (
                          <p className="text-xs">{_.startCase(getCheckName(check))}</p>
                        ))}
                    </div>
                  ) : null}
                </PipelineDealCard>
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </div>
    </>
  );
}
