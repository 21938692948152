import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useEffectOnce from "./useEffectOnce";

export function useEventualState<T>(state: T): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(state);

  useEffect(() => {
    setValue(state);
  }, [state]);

  return [value, setValue];
}

export function useEventualStateOnce<T>(state: T): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(state);

  useEffectOnce(
    () => {
      setValue(state);
    },
    [state],
    state === undefined || state === null
  );

  return [value, setValue];
}
