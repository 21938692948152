import { enums, formatters, types } from "@fraction/shared";
import _ from "lodash";
import { getCheckName } from "src/apps/LOS/utils";
import { cn } from "src/utilities/shadcnUtils";

export const getChecklistItemNameString = (item: types.ChecklistResult, possessive: string = "For") => {
  if (!types.isDocumentChecklistItem(item)) {
    return _.startCase(getCheckName(item));
  }

  let result = "";

  // Primary name
  result += `${item.documentName || _.startCase(item.document)}${item.year ? ` for ${item.year}` : ""}`;

  // Applicant info
  if (item.applicantName && !item.incomeType) {
    result += ` ${possessive} ${item.applicantName}`;
  } else if (item.applicantName && item.incomeType) {
    result += ` ${formatIncomeItem(item, possessive)}`;
  }

  // Debt info
  if (item.debtWithWho) {
    result += ` ${possessive} ${item.debtWithWho || item.debtDescription || ""} ${
      item.debtType ? formatters.enums.DEBT_TYPE_MAP[item.debtType as enums.DebtType]?.toLowerCase() : ""
    }${
      item?.debtCurrentlyOwing
        ? ` (${formatters.number.getCurrencyFromNumber(item?.debtCurrentlyOwing)})`
        : ""
    }`;
  }

  return result;
};

export const ChecklistItemName = ({
  item,
  className,
  primaryNameClassName,
  possessive = "For",
}: {
  item: types.ChecklistResult;
  className?: string;
  primaryNameClassName?: string;
  possessive?: string;
}) => {
  if (!types.isDocumentChecklistItem(item)) {
    return <p>{_.startCase(getCheckName(item))}</p>;
  }

  return (
    <div className={cn("text-sm text-gray-600  ", className)}>
      <p className={cn("text-black", primaryNameClassName)}>
        {item.documentName || _.startCase(item.document)} {item.year ? `for ${item.year}` : ""}
      </p>

      {item.applicantName && !item.incomeType ? (
        <p className="font-medium">
          {possessive} {item.applicantName}
        </p>
      ) : null}
      {item.applicantName && item.incomeType ? (
        <p className="font-medium">{formatIncomeItem(item, possessive)}</p>
      ) : null}
      {item.debtWithWho ? (
        <p className="font-medium">
          {possessive} {item.debtWithWho || item.debtDescription || ""}{" "}
          {item.debtType
            ? formatters.enums.DEBT_TYPE_MAP[item.debtType as enums.DebtType]?.toLowerCase()
            : ""}
          {item?.debtCurrentlyOwing
            ? ` (${formatters.number.getCurrencyFromNumber(item?.debtCurrentlyOwing)})`
            : ""}
        </p>
      ) : null}
    </div>
  );
};

const formatIncomeItem = (item: types.DocumentChecklistItem, possessive: string = "For") => {
  let str = `${possessive} ${formatters.user.possessive(item.applicantName)}`;

  if (item?.incomeAmount) {
    str = `${str} ${formatters.number.getCurrencyWords(item.incomeAmount)}`;
  }

  if (item?.incomeType) {
    str = `${str} ${_.startCase(item.incomeType).toLowerCase()}`;
  }

  const jobTitle = item.incomeJobTitle || item.incomeDescription;
  if (
    item.incomeType &&
    jobTitle &&
    [enums.IncomeType.EMPLOYMENT_INCOME, enums.IncomeType.SELF_EMPLOYMENT_INCOME].includes(
      item.incomeType as enums.IncomeType
    )
  ) {
    return `${str} as a ${jobTitle?.replace("|", "in").toLowerCase()}`;
  }

  return str;
};
