import { enums } from "@fraction/shared";
import { create } from "zustand";
import { StateStorage, createJSONStorage, persist } from "zustand/middleware";

const hashStorage: StateStorage = {
  getItem: (key): string => {
    const searchParams = new URLSearchParams(location.hash.slice(1));
    const storedValue = searchParams.get(key) ?? "";
    return JSON.parse(storedValue);
  },
  setItem: (key, newValue): void => {
    const searchParams = new URLSearchParams(location.hash.slice(1));
    searchParams.set(key, JSON.stringify(newValue));
    location.hash = searchParams.toString();
  },
  removeItem: (key): void => {
    const searchParams = new URLSearchParams(location.hash.slice(1));
    searchParams.delete(key);
    location.hash = searchParams.toString();
  },
};

export interface PortfolioDashStore {
  // empty in this case means not filtering any - default to all
  portfolioFilters?: enums.Portfolio[];
  startDateFilter?: Date;
  endDateFilter?: Date;
  endLoanActivityDateFilter?: Date;
  startLoanActivityDateFilter?: Date;
  countryFilter?: enums.Country;
  productTypeFilter?: enums.ProductType;
  loanStatusFilter?: enums.LoanStatus;
  occupancyFilter?: "primary" | "nonprimary";
  termLengthFilter?: number;
  selectedApp?: string;
  setSelectedApp: (selectedApp?: string) => void;
  setStartDateFilter: (date?: Date) => void;
  setEndDateFilter: (date?: Date) => void;
  setStartLoanActivityDateFilter: (date?: Date) => void;
  setEndLoanActivityDateFilter: (date?: Date) => void;
  setCountryFilter: (country?: enums.Country) => void;
  setPortfolioFilters: (portfolios?: enums.Portfolio[]) => void;
  setProductTypeFilter: (productTypeFilter?: enums.ProductType) => void;
  setLoanStatusFilter: (loanStatusFilter?: enums.LoanStatus) => void;
  setTermLengthFilter: (termLengthFilter?: number) => void;
  setOccupancyFilter: (occupancyFilter?: "primary" | "nonprimary") => void;
  navDrawerOpen: boolean;
  openNavDrawer: () => void;
  closeNavDrawer: () => void;
  toggleNavDrawer: () => void;
}

// put anything that should be considered "global" state for the dashboard in here
const usePortfolioDashStore = create<PortfolioDashStore>()(
  persist(
    (set, get) => ({
      portfolioFilters: undefined,
      startDateFilter: undefined,
      endDateFilter: undefined,
      endLoanActivityDateFilter: undefined,
      startLoanActivityDateFilter: undefined,
      countryFilter: undefined,
      occupancyFilter: undefined,
      productTypeFilter: undefined,
      loanStatusFilter: undefined,
      termLengthFilter: undefined,
      selectedApp: undefined,
      navDrawerOpen: false,
      setSelectedApp: (selectedApp?: string) => set({ selectedApp }),
      setPortfolioFilters: (portfolioFilters?: enums.Portfolio[]) => set({ portfolioFilters }),
      setCountryFilter: (countryFilter?: enums.Country) => set({ countryFilter }),
      setProductTypeFilter: (productTypeFilter?: enums.ProductType) => set({ productTypeFilter }),
      setLoanStatusFilter: (loanStatusFilter?: enums.LoanStatus) => set({ loanStatusFilter }),
      setOccupancyFilter: (occupancyFilter?: "primary" | "nonprimary") => set({ occupancyFilter }),
      setTermLengthFilter: (termLengthFilter?: number) => set({ termLengthFilter }),
      setEndDateFilter: (endDateFilter?: Date) => set({ endDateFilter }),
      setStartDateFilter: (startDateFilter?: Date) => set({ startDateFilter }),
      setEndLoanActivityDateFilter: (endLoanActivityDateFilter?: Date) => set({ endLoanActivityDateFilter }),
      setStartLoanActivityDateFilter: (startLoanActivityDateFilter?: Date) =>
        set({ startLoanActivityDateFilter }),
      openNavDrawer: () => set({ navDrawerOpen: true }),
      closeNavDrawer: () => set({ navDrawerOpen: false }),
      toggleNavDrawer: () => set({ navDrawerOpen: !(get() as any).navDrawerOpen }),
    }),
    {
      name: "portfolio-dash-store",
      storage: createJSONStorage(() => hashStorage),
    }
  )
);

export default usePortfolioDashStore;
