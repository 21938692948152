import { DocumentType, enums, isStatusAfterStatus } from "@fraction/shared";
import { CloudDownloadIcon, Loader } from "lucide-react";
import { useCallback } from "react";
import Skeleton from "src/components/Skeleton";
import { useApplicationAuthed } from "src/hooks/useApplication";
import { useDocgen, useDocumentDownload } from "src/hooks/useDocgen";
import commitmentLetterThumbnail from "src/images/thumbnails/commitment-letter-thumbnail.png";
import fractionDisclosureThumbnail from "src/images/thumbnails/disclosure-2b-thumbnail.png";
import fractionLoanAgreementThumbnail from "src/images/thumbnails/loan-agreement-thumbnail.png";
import { cn } from "src/utilities/shadcnUtils";

const GENERATABLE_DOCUMENTS = [
  {
    types: [DocumentType.SIGNED_COMMITMENT_LETTER, DocumentType.REV_COMMITMENT_LETTER],
    type: DocumentType.UNSIGNED_COMMITMENT_LETTER,
    status: enums.ApplicationStatus.COMMITMENT_LETTER_SENT,
    thumb: commitmentLetterThumbnail,
    title: "Commitment letter",
    description:
      "The commitment letter that outlines the broad terms of the agreement between the borrower and Fraction.",
  },
  {
    types: [DocumentType.FRACTION_DISCLOSURE_2B],
    type: DocumentType.UNSIGNED_FRACTION_DISCLOSURE_2B,
    status: enums.ApplicationStatus.COMMITMENT_LETTER_SENT,
    thumb: fractionDisclosureThumbnail,
    title: "Disclosure 2B",
    description: "A document that explains more details about the costs of the Fraction loan.",
  },
  {
    types: [DocumentType.FRACTION_LOAN_AGREEMENT],
    type: DocumentType.UNSIGNED_FRACTION_LOAN_AGREEMENT,
    status: enums.ApplicationStatus.READY_FOR_INSTRUCTIONS,
    thumb: fractionLoanAgreementThumbnail,
    title: "Loan agreement",
    description:
      "The final loan documents that the borrower will sign with their lawyer at the end of the process.",
    additionalProps: {
      sample: true,
    },
  },
];

export function OutstandingDocumentsToGenerate({
  className,
  applicationId,
}: { className?: string; applicationId?: string }) {
  const { data: app, isFetching } = useApplicationAuthed({
    id: applicationId,
  });

  const downloadGenerateDocument = useDocgen();
  const downloadExistingDocument = useDocumentDownload();

  /**
   * This will only be used if we do not have an existing saved document of that type in the application.
   * We default to download the existing one. If we want to allow for the REGENERATION of new documents,
   * we will want to add that as a button or something.
   */
  const generateDocument = useCallback(
    (type: DocumentType, additionalProps?: Record<string, any>) => {
      if (!app?.id) {
        throw new Error("Application ID not found");
      }
      downloadGenerateDocument.mutateAsync({
        type,
        applicationId: app.id,
        additionalProps,
      });
    },
    [app?.id]
  );

  const downloadDocumentFromId = useCallback(
    (id: string, type: DocumentType) => {
      if (!app?.id) {
        throw new Error("Application ID not found");
      }
      downloadExistingDocument.mutateAsync({
        documentId: id,
        type,
      });
    },
    [app?.id]
  );

  const docsToGenerate = GENERATABLE_DOCUMENTS.filter(
    ({ types }) => !app?.files?.find((file) => file.type?.name && types.includes(file.type?.name))
  )
    .filter(({ status }) => app?.status && isStatusAfterStatus(app?.status, status, true))
    .map((item) => ({
      ...item,
      documentId: app?.files?.find((file) => item.type === file?.type?.name)?.id,
    }));

  if (!docsToGenerate.length) {
    return null;
  }

  if (app?.lenderId !== enums.LenderName.FRACTION) {
    return null;
  }

  return (
    <div className={cn("border p-6 border-gray-400 rounded max-h-[700px] overflow-y-scroll", className)}>
      <div className="flex flex-col">
        <p className="text-2xl font-bold">Outstanding documents</p>
        <p className="mt-2">
          There are a few documents that haven't been signed and uploaded yet—you can download new copies of
          them here in order to get them signed. Note that the loan agreement is a sample document—we will
          send the final version to the borrower's lawyer to have them sign at a signing appointment.
        </p>
      </div>
      <div className="flex flex-row gap-2 items-center mt-3 flex-wrap">
        {isFetching
          ? [1, 2].map((i) => <Skeleton key={`skele-${i}`} className="h-[235px] w-[165.8px]" />)
          : docsToGenerate.map(({ type, thumb, title, description, documentId, additionalProps }) => (
              <button
                onClick={() =>
                  documentId && !additionalProps?.sample
                    ? downloadDocumentFromId(documentId, type)
                    : generateDocument(type, additionalProps)
                }
                key={type}
                className="group border border-gray-500 rounded-md relative flex flex-row"
              >
                <div>
                  <img src={thumb} alt={type} className="rounded-md h-[212px] w-[165.8px]" />
                  <div className="w-full flex items-center justify-center py-1 bg-gray-100 rounded-md">
                    <p className="text-[10px] font-semibold">{title}</p>
                  </div>
                </div>

                {(downloadGenerateDocument.typeLoading || downloadExistingDocument.typeLoading) !== type ? (
                  <div className="group-hover:visible invisible top-0 bottom-0 left-0 right-0 group-hover:bg-gray-600/60 h-full absolute w-full rounded-md flex items-center justify-center flex-col p-4">
                    <CloudDownloadIcon className="text-white h-8 w-8" />
                    <p className="text-white text-xs mt-2 leading-3 mb-2 font-semibold text-center">
                      Download
                      <br />
                      {title}
                    </p>
                    <p className="text-[9px] text-center text-white leading-3">{description}</p>
                  </div>
                ) : null}
                {(downloadGenerateDocument.typeLoading || downloadExistingDocument.typeLoading) === type ? (
                  <div className="top-0 bottom-0 left-0 right-0 h-full absolute w-full rounded-md flex items-center justify-center flex-col p-4">
                    <Loader height={30} className="inline-block ml-1 animate-spin" />
                  </div>
                ) : null}
              </button>
            ))}
      </div>
    </div>
  );
}
