import { Global, css } from "@emotion/react";
import { PortfolioOverviewCards } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/PortfolioOverviewCards";
import { BorrowerAgeChart } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/BorrowerAge";
import { BorrowerCreditScoreChart } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/BorrowerCreditScore";
import { BorrowerCreditScoreDeltaChart } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/BorrowerCreditScoreDelta";
import { BrokerVsDirect } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/BrokerVsDirect";
import { CollateralGroupings } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/CollateralGroupings";
import { FundingsCalendar } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/FundingsCalendar";
import { IncomeSources } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/IncomeSources";
import { LoanSizeChart } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/LoanSize";
import { LoanToValue } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/LoanToValue";
// import { LoanToValueOverTime } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/LoanToValueOverTime";
import { PayoutGroupings } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/PayoutGroupings";
import { PayoutsOverTime } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/PayoutsOverTime";
import { PortfolioSizeChart } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/PortfolioSize";
import { ProjectedMaturitiesChart } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/ProjectedMaturities";
// import { PropertyValueOverTime } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/PropertyValueOverTime";
import { TdsAndGds } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/TdsAndGds";
import { UseOfFunds } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/UseOfFunds";
import { WeightedAverageMaturity } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/charts/WeightedAverageMaturity";

import { PropertyMsaChart } from "./charts/PropertyMsaChart";
import { PropertyTypeChart } from "./charts/PropertyType";
import { WeightedAverageCoupon } from "./charts/WeightedAverageCoupon";

const OVERFLOW_VISIBLE = css`
  svg {
    overflow: visible;
  }
`;

export function PortfolioAnalyticsScreen() {
  return (
    <div className="flex flex-wrap lg:grid grid-cols-3 gap-2 p-5 overflow-hidden">
      <Global styles={OVERFLOW_VISIBLE} />
      <PortfolioOverviewCards />
      <BorrowerAgeChart />
      <PropertyTypeChart />
      <IncomeSources />
      <LoanSizeChart />
      <BorrowerCreditScoreChart />
      <BorrowerCreditScoreDeltaChart />
      <PropertyMsaChart />
      <LoanToValue />
      <PortfolioSizeChart />
      <FundingsCalendar />
      {/* <LoanToValueOverTime /> */}
      {/* <PropertyValueOverTime /> */}
      <PayoutsOverTime />
      <PayoutGroupings />
      <UseOfFunds />
      <BrokerVsDirect />
      <CollateralGroupings />
      <WeightedAverageCoupon />
      <WeightedAverageMaturity />
      <ProjectedMaturitiesChart />
      <TdsAndGds />
    </div>
  );
}
