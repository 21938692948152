import { entities, formatters, utilities } from "@fraction/shared";
import { parseISO } from "date-fns";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ModifiableKeyValue } from "src/components/ModifiableKeyValue";

export function RequestedCloseDateModifiableKeyValue({
  app,
  loading,
}: {
  app?: entities.ApplicationT;
  loading?: boolean;
}) {
  const minDate = utilities.bankHolidays.getAppropriateNextClosingDateForApp(app);

  const handleCloseDateChange = useCallback((dateStr: string) => {
    const date = parseISO(dateStr);
    return formatters.date.iso8601(
      utilities.bankHolidays.getAppropriateClosingDateForApp(date, app?.lenderId, toast.info)
    );
  }, []);

  return (
    <ModifiableKeyValue
      appId={app?.id}
      label="Requested close date"
      loading={loading}
      path="requestedCloseDate"
      type="date"
      formatValue={formatters.date.iso8601}
      mutateValueBeforeUpdate={handleCloseDateChange}
      minDate={minDate}
    />
  );
}

export function CustomLabelRequestedCloseDateModifiableKeyValue({
  app,
  loading,
  label,
}: {
  app?: entities.ApplicationT;
  loading?: boolean;
  label: string;
}) {
  const minDate = utilities.bankHolidays.getAppropriateNextClosingDateForApp(app);
  const handleCloseDateChange = useCallback((dateStr: string) => {
    const date = parseISO(dateStr);
    return formatters.date.iso8601(
      utilities.bankHolidays.getAppropriateClosingDateForApp(date, app?.lenderId, toast.info)
    );
  }, []);

  return (
    <ModifiableKeyValue
      appId={app?.id}
      label={label}
      loading={loading}
      path="requestedCloseDate"
      type="date"
      formatValue={formatters.date.iso8601}
      mutateValueBeforeUpdate={handleCloseDateChange}
      minDate={minDate}
    />
  );
}

export function EstimatedCloseDateModifiableKeyValue({
  app,
  loading,
}: {
  app?: entities.ApplicationT;
  loading?: boolean;
}) {
  const minDate = utilities.bankHolidays.getAppropriateNextClosingDateForApp(app);
  const handleCloseDateChange = useCallback((dateStr: string) => {
    const date = parseISO(dateStr);
    return formatters.date.iso8601(
      utilities.bankHolidays.getAppropriateClosingDateForApp(date, app?.lenderId, toast.info)
    );
  }, []);

  return (
    <ModifiableKeyValue
      appId={app?.id}
      label="Estimated close date"
      loading={loading}
      path="estimatedCloseDate"
      type="date"
      formatValue={formatters.date.iso8601}
      mutateValueBeforeUpdate={handleCloseDateChange}
      minDate={minDate}
    />
  );
}
