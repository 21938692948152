import { constants, calculators, enums, formatters } from "@fraction/shared";
import { useCallback } from "react";
import { useBrokerAccount } from "./useBrokerAccount";

const brokerTiers: Record<
  enums.BrokerType,
  {
    title: string;
    tier: enums.BrokerType;
    next?: enums.BrokerType;
    dealsRequiredToNextTier?: number;
    bps: number;
    multiplier: number;
  }
> = {
  [enums.BrokerType.STANDARD]: {
    tier: enums.BrokerType.STANDARD,
    title: "Standard",
    next: enums.BrokerType.BRONZE,
    dealsRequiredToNextTier: constants.FUNDED_DEALS_FOR_BRONZE_BROKER,
    bps: 0,
    multiplier: 0,
  },
  [enums.BrokerType.BRONZE]: {
    tier: enums.BrokerType.BRONZE,
    title: "Bronze",
    next: enums.BrokerType.SILVER,
    dealsRequiredToNextTier: constants.FUNDED_DEALS_FOR_SILVER_BROKER,
    bps: constants.BPS_FOR_BRONZE_BROKER,
    multiplier: 1,
  },
  [enums.BrokerType.SILVER]: {
    tier: enums.BrokerType.SILVER,
    title: "Silver",
    next: enums.BrokerType.GOLD,
    dealsRequiredToNextTier: constants.FUNDED_DEALS_FOR_GOLD_BROKER,
    bps: constants.BPS_FOR_SILVER_BROKER,
    multiplier: 2,
  },
  [enums.BrokerType.GOLD]: {
    tier: enums.BrokerType.GOLD,
    title: "Gold",
    bps: constants.BPS_FOR_GOLD_BROKER,
    multiplier: 4,
  },
};

export function useBrokerTier() {
  const { data: brokerAccount, isLoading } = useBrokerAccount();
  const brokerType: enums.BrokerType = brokerAccount?.brokerType || enums.BrokerType.STANDARD;
  const currentTier = brokerTiers[brokerType];

  const numberOfDealsToNextTier = Math.max(
    currentTier?.dealsRequiredToNextTier
      ? currentTier?.dealsRequiredToNextTier - (brokerAccount?.numberOfDealsLastYear || 0)
      : 0,
    0
  );

  const progressToNextTier = formatters.number.getPercentageFromDecimal(
    (brokerAccount?.numberOfDealsLastYear || 0) /
      (currentTier?.dealsRequiredToNextTier || brokerAccount?.numberOfDealsLastYear || 1)
  );

  const calculatePoints = useCallback(
    (cents: number) =>
      currentTier?.bps !== undefined
        ? Math.floor(
            (calculators.points.bpsToPercent(currentTier?.bps) * formatters.number.getDollars(cents)) /
              constants.DOLLARS_PER_REWARDS_POINT
          )
        : undefined,
    [currentTier?.bps]
  );

  return {
    brokerAccount,
    isLoading,
    brokerType,
    currentTier,
    nextTier: currentTier.next ? brokerTiers[currentTier.next] : undefined,
    numberOfDealsToNextTier,
    progressToNextTier,
    calculatePoints,
  };
}
