import { enums, types } from "@fraction/shared";
import _ from "lodash";
import { ChecklistApp } from "src/api/fraction";
import { getCheckName } from "src/apps/LOS/utils";
import { ChecklistItemName } from "./ChecklistItemName";

export const AppChecklistItems = ({
  app,
  status,
}: {
  app?: ChecklistApp;
  status?: enums.ApplicationStatus | null;
}) => {
  if (!app || !status) {
    return null;
  }

  const itemsForChecklist = app?.checklists?.[status]?.filter((check) => !check.ok);

  const documentsForChecklist = itemsForChecklist
    ?.filter(types.isDocumentChecklistItem)
    ?.filter((x) => !x.document?.toLowerCase()?.includes("unsigned"));
  const tasksForChecklist = itemsForChecklist?.filter(types.isTaskChecklistItem);
  const fieldsForChecklist = itemsForChecklist?.filter(types.isFieldChecklistItem);

  const documentsNeedingApproval = documentsForChecklist?.filter(
    (item) => item.approval !== enums.ApplicationTaskApprovalStatus.APPROVED && !!item.documentUrl
  );
  const documentsNotUploaded = documentsForChecklist?.filter((item) => !item.documentUrl);

  return (
    <>
      {app.employeeTasks?.length ? (
        <div>
          <b>Digest Tasks</b>
          {app.employeeTasks?.map((task) => (
            <div key={task.id}>
              <p className="text-xs">- {task.taskDescription}</p>
            </div>
          ))}
        </div>
      ) : null}
      {documentsNotUploaded?.length ? (
        <div>
          <h3 className="text-md font-bold">Documents not uploaded</h3>
          <ul className="flex flex-col gap-0">
            {documentsNotUploaded?.map((doc) => (
              <li className="inline-flex flex-row items-center text-xs">
                -{" "}
                <ChecklistItemName
                  key={`${doc.document}-${doc.applicantId}-${doc.debtId}-${doc.incomeId}`}
                  className="ml-0.5 text-black inline-flex flex-row text-xs gap-0.5 line-clamp-1 text-ellipsis truncate"
                  primaryNameClassName="text-xs"
                  item={doc}
                  possessive="for"
                />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {documentsNeedingApproval?.length ? (
        <div>
          <h3 className="text-md font-bold">Documents needing approval</h3>
          <ul className="flex flex-col gap-0">
            {documentsNeedingApproval?.map((doc) => (
              <li className="inline-flex flex-row items-center text-xs">
                -
                <ChecklistItemName
                  key={`${doc.document}-${doc.applicantId}-${doc.debtId}-${doc.incomeId}`}
                  className="ml-0.5 text-black inline-flex flex-row text-xs gap-0.5"
                  primaryNameClassName="text-xs"
                  item={doc}
                  possessive="for"
                />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {tasksForChecklist?.length ? (
        <div>
          <h3 className="text-md font-bold">Tasks to complete</h3>
          {tasksForChecklist?.map((task) => (
            <p
              className="text-xs"
              key={`${(task as any).task}-${task.applicantId}-${task.debtId}-${task.incomeId}`}
            >
              - {_.startCase(getCheckName(task))}
            </p>
          ))}
        </div>
      ) : null}
      {fieldsForChecklist?.length ? (
        <div>
          <h3 className="text-md font-bold">Fields to fill out</h3>
          {fieldsForChecklist?.map((field) => (
            <p
              className="text-xs"
              key={`${(field as any).field}-${field.applicantId}-${field.debtId}-${field.incomeId}`}
            >
              - {_.startCase(getCheckName(field))}
            </p>
          ))}
        </div>
      ) : null}
    </>
  );
};
