import { create } from "zustand";

export interface PostFundedDashStore {
  navDrawerOpen: boolean;
  openNavDrawer: () => void;
  closeNavDrawer: () => void;
  toggleNavDrawer: () => void;
}

// put anything that should be considered "global" state for the dashboard in here
const usePostFundedDashStore = create<PostFundedDashStore>((set) => ({
  navDrawerOpen: false,
  openNavDrawer: () => set({ navDrawerOpen: true }),
  closeNavDrawer: () => set({ navDrawerOpen: false }),
  toggleNavDrawer: () => set(({ navDrawerOpen }) => ({ navDrawerOpen: !navDrawerOpen })),
}));

export default usePostFundedDashStore;
