import { useMemo } from "react";
import { Logger } from "src/log";

import { engine, enums, parsers, selectors } from "@fraction/shared";
import _ from "lodash";
import { useRates } from "./useRates";

const defaultLogger = new Logger("hooks.useCurrentRate");

type UseCurrentRateOptions = Omit<parsers.rate.FetchRateArgs, "onlyCurrent" | "regions"> & {
  logger?: Logger;
} & {
  allowAnyRegion?: boolean;
  onError?: (err: Error) => void;
};

/**
 * Fetch a current rate. Assumes a 5-year term but this can be overridden
 */
const useCurrentRate = (administrativeArea?: string | undefined, options: UseCurrentRateOptions = {}) => {
  const { allowAnyRegion, ...rateOptions } = options;
  const logger = options.logger || defaultLogger;

  const rates = useRates();

  const region = useMemo(() => {
    if (!administrativeArea) {
      return;
    }

    const result = engine.subprocessors.adminAreaValidationSubProcessor(administrativeArea, true);

    if (!allowAnyRegion && result.status !== enums.EngineRuleStatus.PASSED) {
      logger.log(`Administrative area validation ${result.status}, not fetching rate: ${result.message}`);
      return;
    }

    const region_ = selectors.property.selectRegionFromAdministrativeArea(administrativeArea);
    if (!region_) {
      logger.warn(`No region found for administrative area ${administrativeArea}, skipping rate fetch`);
      return;
    }

    return region_;
  }, [administrativeArea, logger, allowAnyRegion]);

  return {
    ...rates,
    rate: _.maxBy(
      rates.data?.filter(
        (rate) =>
          (!region || rate.regions?.includes(region)) &&
          (!rateOptions.termLength || rate.termLength === rateOptions.termLength)
      ),
      "maximumLTV"
    ),
  };
};

export default useCurrentRate;
