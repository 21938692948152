import { cn } from "src/utilities/shadcnUtils";

export const AppChatContainer = ({
  children,
  className,
  loading,
}: { children: React.ReactNode; className?: string; loading?: boolean }) =>
  loading ? null : (
    <div
      className={cn(
        "flex flex-row gap-2 fixed bottom-0 right-20 z-10 h-0 justify-end items-end overflow-visible",
        className
      )}
    >
      {children}
    </div>
  );
