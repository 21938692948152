import { useDroppable } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { entities, enums, formatters, notUndefinedOrNull, selectors } from "@fraction/shared";
import { useVirtualizer } from "@tanstack/react-virtual";
import _ from "lodash";
import { ChevronsRightLeft } from "lucide-react";
import { forwardRef, useCallback, useRef } from "react";
import { ChecklistApp } from "src/api/fraction";
import { PipelineDealCard } from "src/apps/LOS/components/PipelineDealCard";
import { useEventualState } from "src/hooks/useEventualState";
import useMergedRef from "src/hooks/useMergedRef";

interface SortableItemProps {
  id: string;
  statusOverride?: string;
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  showChecklist?: boolean;
  showSalesPerson?: boolean;
  salesOptions?: entities.InternalEmployee[];
  style?: any;
  draggable?: boolean;
}

export const SortableItem = forwardRef<HTMLDivElement, SortableItemProps>(
  ({ draggable = true, ...props }: SortableItemProps, ref) => {
    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
      id: props.id,
      disabled: !draggable,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      ...props.style,
    };

    const mergedRef = useMergedRef(ref, setNodeRef);

    const { showChecklist, ...rest } = props;

    return (
      <PipelineDealCard
        {...rest}
        statusOverride={
          props.statusOverride ||
          (props.id === active?.id ? active?.data?.current?.sortable?.containerId : undefined)
        }
        ref={mergedRef}
        style={style}
        hideStatus={!showChecklist}
        {...attributes}
        {...listeners}
      />
    );
  }
);

interface PipelineStageContainerProps {
  id: string;
  items?: ChecklistApp[];
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  showChecklist?: boolean;
  showSalesPerson?: boolean;
  salesOptions?: entities.InternalEmployee[];
  style: any;
  showLenderName?: boolean;
  draggable?: boolean;
  "data-index": number;
  startEmptyCollapsed?: boolean;
}

const PipelineStageContent = (
  props: PipelineStageContainerProps & {
    collapsed?: boolean;
    toggleCollapsed?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  }
) => {
  if (!props.collapsed) {
    return <PipelineStageUncollapsedContent {...props} />;
  }

  return <PipelineStageCollapsedContent {...props} />;
};

const PipelineStageUncollapsedContent = ({
  id,
  items,
  setShowModal,
  showChecklist,
  showSalesPerson,
  showLenderName,
  salesOptions,
  draggable,
  toggleCollapsed,
}: PipelineStageContainerProps & {
  collapsed?: boolean;
  toggleCollapsed?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { setNodeRef } = useDroppable({
    id,
  });
  const parentRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergedRef(parentRef, setNodeRef);
  const virtualizer = useVirtualizer({
    count: items?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 200,
    getItemKey: (index) => items?.[index]?.id || "",
    gap: 6,
    overscan: 4,
  });

  return (
    <>
      <div className="pr-4 mb-1 flex flex-row gap-x-2 justify-between items-center">
        <button className="bg-gray-200 hover:bg-gray-300 rounded-md p-1 px-4" onClick={toggleCollapsed}>
          <ChevronsRightLeft className="w-4 h-4" />
        </button>
        <p title={_.startCase(id)} className="text-md font-bold text-ellipsis line-clamp-1">
          {_.startCase(id)}
        </p>
        <p className="bg-gray-400 text-black font-semibold rounded-full h-6 text-xs text-center w-8 flex items-center justify-center">
          {items?.length || 0}
        </p>
      </div>
      <SortableContext id={id} items={items || []} strategy={verticalListSortingStrategy}>
        <div
          className="bg-gray-200 rounded-lg p-4 h-[60svh] w-80 overflow-y-scroll overscroll-y-contain"
          ref={mergedRef}
        >
          <div
            css={{
              height: virtualizer.getTotalSize(),
              width: "100%",
              position: "relative",
            }}
          >
            {virtualizer.getVirtualItems().map((virtualItem) => (
              <SortableItem
                draggable={draggable}
                ref={virtualizer.measureElement}
                data-index={virtualItem.index}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                showChecklist={showChecklist}
                setShowModal={setShowModal}
                showSalesPerson={showSalesPerson}
                showLenderName={showLenderName}
                key={virtualItem?.key}
                // @ts-ignore
                id={virtualItem.key}
                salesOptions={salesOptions}
              />
            ))}
          </div>
        </div>
      </SortableContext>
      <div className="px-4 mt-1 flex flex-col align-middle items-center">
        <p>
          <b>Total:</b>{" "}
          {formatters.number.getCurrencyFromNumber(
            _.sumBy(items, selectors.application.selectApplicationAmount),
            { digits: 0 }
          )}
        </p>
      </div>
    </>
  );
};

const PipelineStageCollapsedContent = ({
  id,
  items,
  toggleCollapsed,
}: PipelineStageContainerProps & {
  collapsed?: boolean;
  toggleCollapsed?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <div className="h-full flex flex-col justify-center items-center group w-12 pb-5">
      <button
        className="bg-gray-200 group-hover:bg-gray-300 rounded-md p-1 px-4 w-fit mb-1"
        onClick={toggleCollapsed}
      >
        <ChevronsRightLeft className="w-4 h-4" />
      </button>
      <div
        // @ts-ignore
        onClick={toggleCollapsed}
        className="bg-gray-200 rounded-lg flex items-start w-full justify-center h-full text-right cursor-pointer group-hover:bg-gray-300"
      >
        <div className="text-md font-bold -rotate-90 whitespace-nowrap">
          <div className="-translate-x-[55%] flex flex-row items-center gap-x-2">
            <p className="bg-gray-400 text-black font-semibold rounded-full h-6 text-xs text-center w-8 flex items-center justify-center">
              {items?.length || 0}
            </p>
            <p title={_.startCase(id)}>{_.startCase(id)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PipelineStageContainer = forwardRef<HTMLDivElement, PipelineStageContainerProps>(
  (props, ref) => {
    const filteredItems = props.items?.filter(notUndefinedOrNull) || [];
    const [isCollapsed, setIsCollapsed] = useEventualState(
      props.startEmptyCollapsed ? !filteredItems.length : false
    );

    const toggleCollapsed = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      setIsCollapsed((prev) => !prev);
    }, []);

    return (
      <div data-index={props["data-index"]} css={props.style} ref={ref}>
        <PipelineStageContent
          {...props}
          collapsed={isCollapsed}
          toggleCollapsed={toggleCollapsed}
          items={filteredItems}
        />
      </div>
    );
  }
);
