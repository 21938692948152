import _ from "lodash";
import { useMemo } from "react";

import { SimpleStatus, getCurrentStatusBlock } from "src/components/AppStatusBadge";
import { AppsPipelineView } from "src/components/AppsPipelineView";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationsQuery } from "src/hooks/useApplication";
import { useBrokerTier } from "src/hooks/useBrokerTier";
import { cn } from "src/utilities/shadcnUtils";

export const BrokerDashboardOverview = ({
  status = "active",
  accountType,
}: { status?: "active" | "all" | "closed"; accountType: "broker" | "conveyancer" }) => {
  const {
    data: applications,
    isFetching,
    refetch,
    dataUpdatedAt,
  } = useApplicationsQuery({
    status,
    accountType,
    refetch: true,
    initialRefetch: true,
  });
  const count = applications?.length;

  useDocumentTitle(`${_.startCase(status)} deals | Fraction`);

  const modifiedWithStatus = useMemo(() => {
    return applications?.map((application) => {
      return {
        ...application,
        simpleStatus: getCurrentStatusBlock(application.syntheticStatus)?.label,
      };
    });
  }, [applications]);

  return (
    <AppsPipelineView
      draggable={false}
      apps={modifiedWithStatus}
      isFetching={isFetching}
      handleRefetchClick={refetch}
      totalCount={count}
      dataUpdatedAt={dataUpdatedAt}
      groupBy="simpleStatus"
      className="pt-6 pl-6"
      visibleStages={
        status === "active"
          ? [
              SimpleStatus.RequiresMoreInformation,
              SimpleStatus.Underwriting,
              SimpleStatus.DraftingCommitmentLetter,
              SimpleStatus.CommitmentLetterSent,
              SimpleStatus.CollectingDocuments,
              SimpleStatus.EngagingConveyancer,
              SimpleStatus.ExpiredTerms,
            ]
          : [SimpleStatus.Funded, SimpleStatus.Rescinded, SimpleStatus.Rejected]
      }
      showChecklist
      showSalesPerson={false}
      showLenderName={false}
      startEmptyCollapsed
    />
  );
};

export default function StatusProgress({ className }: { className?: string }) {
  const { progressToNextTier, numberOfDealsToNextTier, nextTier, currentTier } = useBrokerTier();

  return (
    <div className={cn("gap-y-2", className)}>
      <div className="rounded-full bg-gray-400 h-2 w-full">
        <div className="h-full rounded bg-green" style={{ width: progressToNextTier, maxWidth: "100%" }} />
      </div>
      <p className="font-normal text-sm text-gray-600">
        {nextTier ? (
          <p>
            You need {numberOfDealsToNextTier} more funded deals to reach {nextTier?.title} status.
          </p>
        ) : (
          <p>You are a {currentTier.title} broker and get the best perks.</p>
        )}
      </p>
    </div>
  );
}
