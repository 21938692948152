import { enums } from "@fraction/shared";
import _ from "lodash";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";

export function LenderSelect({
  onValueChange,
  className,
  value,
}: {
  value?: string;
  onValueChange?: (value: string | null) => void;
  className?: string;
}) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={className}>
        <SelectValue placeholder="Lender select" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Overall</SelectLabel>
          <SelectItem value="all">All Lenders</SelectItem>
          <SelectItem value={enums.LenderName.FRACTION}>Fraction</SelectItem>
          <SelectItem value="others">All Whitelabels</SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup>
          <SelectLabel>Specific lenders</SelectLabel>
          {Object.values(enums.LenderName)
            .filter((name) => name !== enums.LenderName.FRACTION)
            .map((name) => (
              <SelectItem key={name} value={name}>
                {_.startCase(name)}
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
