import { entities, enums, formatters, plainToInstance, selectors } from "@fraction/shared";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect } from "react";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useMutation, useQuery } from "src/lib";
import { v5 as uuidv5 } from "uuid";
import { useAccountViewPreference } from "./useAccountViewPreference";
import { useAuthenticatedWebsocket } from "./useAuthenticatedWebsocket";
const NAMESPACE = "f9b2c51f-3a9a-4b1d-9c0e-0d1a1b9a1a2a";

export function useChat({
  applicationId,
  type = enums.ChatType.CONVEYANCER_EMPLOYEE,
  enabled = true,
  initialData,
}: {
  applicationId?: string;
  type?: enums.ChatType;
  enabled?: boolean;
  initialData?: entities.Chat[];
}) {
  const { isAuthenticated, user } = useAuth();
  const { preference } = useAccountViewPreference();
  const queryClient = useQueryClient();
  const { lastMessage, readyState } = useAuthenticatedWebsocket({
    routeKey: formatters.application.applicationChatRouteKey(applicationId, type),
    enabled,
  });

  const q = useQuery({
    enabled: !!applicationId && isAuthenticated && enabled,
    queryKey: ["appChat", applicationId, type, isAuthenticated],
    queryFn: () => (applicationId ? fraction.getChat({ applicationId, type }) : null),
    select: (chats) =>
      useGroupedUserChat(
        chats?.map((x) => ({
          ...x,
          user: selectors.user.anonymizedUser(x.user, preference === "broker"),
        }))
      ),
    initialData,
  });

  const members = useQuery({
    enabled: !!applicationId && isAuthenticated && enabled,
    queryKey: ["appChatMembers", applicationId, type, isAuthenticated],
    queryFn: () => (applicationId ? fraction.getChatMembers({ applicationId, type }) : null),
  });

  useEffect(() => {
    if (lastMessage) {
      queryClient.setQueryData(
        ["appChat", applicationId, type, isAuthenticated],
        (oldChats: entities.Chat[]) => {
          return _.uniqBy(
            _.sortBy(
              [
                ...(oldChats || []),
                plainToInstance(entities.Chat, {
                  ...(lastMessage?.data || {}),
                  user: selectors.user.anonymizedUser(lastMessage?.data?.user, preference === "broker"),
                } as object),
              ],
              "createdAt"
            ),
            // if there is a ts, use that to dedupe
            (x) => (x?.ts ? x.ts : x.id)
          );
        }
      );
    }
  }, [lastMessage]);

  const sendMessage = useMutation({
    mutationFn: ({ message, applicationId }: { message: string; applicationId: string }) => {
      const createdAt = new Date();
      const ts = createdAt.getTime();

      queryClient.setQueryData(
        ["appChat", applicationId, type, isAuthenticated],
        (oldChats: entities.Chat[]) => {
          return [
            ...(oldChats || []),
            new entities.Chat({
              user: user || undefined,
              userId: user?.id,
              id: uuidv5(`${message}-${createdAt.toISOString()}`, NAMESPACE),
              createdAt,
              message,
              applicationId,
              type,
              ts,
            }),
          ];
        }
      );
      return fraction.sendChat({ message, applicationId, type, ts });
    },
    onError: (e) => {
      if (applicationId) {
        return queryClient.invalidateQueries({ queryKey: ["appChat", applicationId, type, isAuthenticated] });
      }
    },
  });

  return {
    ...q,
    isLoading: q.isLoading || members.isLoading,
    chats: q.data,
    members: members.data?.filter((u) => u.id !== user?.id),
    sendMessage: sendMessage.mutateAsync,
    isPending: sendMessage.isPending,
    readyState,
  };
}

export function useGroupedUserChat(chats_: entities.Chat[] | undefined | null) {
  const chats = chats_ || [];
  const userChats = [];
  let currentUser: string | undefined;
  let currentUserChats: entities.Chat[] = [];

  for (let i = 0; i < (chats || []).length; i++) {
    const chat = chats[i];
    if (!chat.userId) {
      continue;
    }

    if (currentUser === chat.userId) {
      currentUserChats.push(chat);
    } else {
      if (currentUserChats?.length) {
        userChats.push(currentUserChats);
      }
      currentUserChats = [chat];
      currentUser = chat.userId;
    }

    if (i === chats.length - 1) {
      userChats.push(currentUserChats);
    }
  }

  return userChats.filter((c) => c.length).map((x) => ({ user: x?.[0]?.user, chats: x }));
}
