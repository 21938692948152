import { formatters, types } from "@fraction/shared";
import { useQuery } from "@tanstack/react-query";
import fraction from "src/api/fraction";
import { Logger } from "src/log";

const logger = new Logger("usePropertyValuation");

export function usePropertyValuation(propertyAddress?: string | types.ExtractedPropertyAddress) {
  const formattedAddress =
    typeof propertyAddress === "string"
      ? propertyAddress
      : propertyAddress
      ? formatters.property.shortFormattedAddress(propertyAddress)
      : undefined;

  return useQuery({
    enabled: !!formattedAddress,
    queryKey: ["propertyValuation", formattedAddress],
    queryFn: async () => {
      if (!propertyAddress) {
        return;
      }
      try {
        const { value } = await fraction.getPropertyValuation(propertyAddress);
        return value;
      } catch (err) {
        logger.exception(err, "Unable to fetch property valuation");
        return null;
      }
    },
  });
}
