import { enums, isStatusAfterStatus } from "@fraction/shared";
import { Route, Routes, useParams } from "react-router-dom";
import { useAuth } from "src/auth";
import { AppChat } from "src/components/AppChat";
import { AppChatContainer } from "src/components/AppChatContainer";
import { AppComms } from "src/components/AppComms";
import { AppDetailsOverview } from "src/components/AppDetailsOverview";
import { AppDocumentCreation } from "src/components/AppDocumentCreation";
import { AppDocumentList } from "src/components/AppDocumentList";
import { AppDocumentUpload } from "src/components/AppDocumentUpload";
import { AppOverviewShell } from "src/components/AppOverviewShell";
import { OutstandingDocumentsToGenerate } from "src/components/OutstandingDocumentsToGenerate";
import { useApplicationAuthed } from "src/hooks/useApplication";

/**
 * inner tabs w/ routes
 */
export function LOSAppOverviewShell() {
  const { id } = useParams();
  const { user } = useAuth();

  const employeeTypes = user?.internalEmployee?.types || [];

  const { data: app, isLoading: loading } = useApplicationAuthed({
    id,
    initialRefetch: true,
  });

  const talkToBrokerDirectly =
    (employeeTypes.includes(enums.InternalEmployeeType.MORTGAGE_SPECIALIST) ||
      employeeTypes.includes(enums.InternalEmployeeType.BACK_OFFICE_SUPPORT) ||
      employeeTypes.includes(enums.InternalEmployeeType.BDM)) &&
    !!app?.brokers?.length &&
    (!app?.lenderId || app?.lenderId === enums.LenderName.FRACTION);

  const talkToBrokerAndLenderDirectly =
    (employeeTypes.includes(enums.InternalEmployeeType.MORTGAGE_SPECIALIST) ||
      employeeTypes.includes(enums.InternalEmployeeType.BACK_OFFICE_SUPPORT) ||
      employeeTypes.includes(enums.InternalEmployeeType.BDM)) &&
    !!app?.brokers?.length &&
    app?.lenderId &&
    app?.lenderId !== enums.LenderName.FRACTION;

  const talkToConveyancerDirectly =
    (employeeTypes.includes(enums.InternalEmployeeType.MORTGAGE_ADMINISTRATOR) ||
      employeeTypes.includes(enums.InternalEmployeeType.BACK_OFFICE_SUPPORT)) &&
    app?.conveyancerGroupId &&
    app?.status &&
    isStatusAfterStatus(app?.status, enums.ApplicationStatus.CONVEYANCER_ENGAGED, true) &&
    (!app?.lenderId || app?.lenderId === enums.LenderName.FRACTION);

  const talkToConveyancerWithLender =
    (employeeTypes.includes(enums.InternalEmployeeType.MORTGAGE_ADMINISTRATOR) ||
      employeeTypes.includes(enums.InternalEmployeeType.BACK_OFFICE_SUPPORT)) &&
    app?.conveyancerGroupId &&
    app?.status &&
    isStatusAfterStatus(app?.status, enums.ApplicationStatus.CONVEYANCER_ENGAGED, true) &&
    app?.lenderId !== enums.LenderName.FRACTION;

  const talkToLender =
    (employeeTypes.includes(enums.InternalEmployeeType.MORTGAGE_SPECIALIST) ||
      employeeTypes.includes(enums.InternalEmployeeType.BACK_OFFICE_SUPPORT)) &&
    app?.lenderId &&
    app?.lenderId !== enums.LenderName.FRACTION;

  return (
    <div>
      <AppOverviewShell
        showApproveReject
        app={app || undefined}
        loading={loading}
        tabs={["overview", "doc-upload", "doc-list", "comms"]}
      />
      <AppChatContainer loading={loading}>
        {talkToConveyancerDirectly ? (
          <AppChat
            className="shadow"
            expandedClassName="h-[507px]"
            applicationId={app?.id}
            type={enums.ChatType.CONVEYANCER_EMPLOYEE}
          />
        ) : null}
        {talkToConveyancerWithLender ? (
          <AppChat
            className="shadow"
            expandedClassName="h-[507px]"
            applicationId={app?.id}
            type={enums.ChatType.LENDER_CONVEYANCER}
          />
        ) : null}
        {talkToBrokerDirectly ? (
          <AppChat
            className="shadow"
            expandedClassName="h-[507px]"
            applicationId={id}
            type={enums.ChatType.BROKER_EMPLOYEE}
          />
        ) : null}
        {talkToBrokerAndLenderDirectly ? (
          <AppChat
            className="shadow"
            expandedClassName="h-[507px]"
            applicationId={id}
            type={enums.ChatType.LENDER_BROKER_EMPLOYEE}
          />
        ) : null}
        {talkToLender ? (
          <AppChat
            className="shadow"
            expandedClassName="h-[507px]"
            applicationId={id}
            type={enums.ChatType.LENDER_EMPLOYEE}
          />
        ) : null}
      </AppChatContainer>
    </div>
  );
}

export function AppDetailsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LOSAppOverviewShell />}>
        <Route
          path="/"
          element={
            <AppDetailsOverview>
              <OutstandingDocumentsToGenerate />
            </AppDetailsOverview>
          }
        />
        <Route path="/doc-upload" element={<AppDocumentUpload />} />
        <Route path="/doc-gen" element={<AppDocumentCreation />} />
        <Route path="/doc-list" element={<AppDocumentList mutatable />} />
        <Route path="/comms" element={<AppComms />} />
      </Route>
    </Routes>
  );
}
